.block.home-banner {
    margin-bottom: 0;

    & + * {
        margin-top: 0;
    }

    .home-banner--video-container {
        position: relative;
        max-width: 100%;
        height: 100vh;

        .home-banner--video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
    
            &:after {
                content: '';
                position: absolute;
                inset: 0;
                background-color: rgb(0 0 0 / 0.6);
                z-index: 10;
            }
    
            video {
                max-width: none;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .home-banner--container {
            position: relative;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
    
            &>h1 {
                text-align: center;
                font-size: calc( 40px + 5 * (100vw - 400px) / (1440 - 400) );
                text-wrap: balance;
    
                span.home-banner--word {
                    display: inline-block;
                    opacity: 0;
                }
            }
        }
    }

    .home-banner--introduction {
        --color: #000000;
        --bg: #FFFFFF;

        padding-top: 100px;
        padding-bottom: 100px;
        background-color: #FFFFFF;
        color: #000000;
        display: flex;
        flex-wrap: wrap;
        row-gap: 1.5rem;

        & > div {
            flex: 1 1 0%;
            min-width: 300px;

            &:first-child {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                gap: 1.5rem;
                padding-right: 3.75%;

                & > h2 {
                    text-wrap: balance;
                    font-size: calc( 40px + 5 * (100vw - 400px) / (1440 - 400) );
                }
            }

            &:last-child {
                font-size: calc( 19px + (23 - 19) * (100vw - 400px) / (1440 - 400) );
            }
        }

        .new-btn {
            border-color: var(--color);

            &:after {
                display: none;
            }

            &:hover {
                color: var(--bg);
                background-color: var(--color);
            }
        }
    }

    &.is-preview {
        .home-banner--container {
            &>h1 {
                span.home-banner--word {
                    opacity: 1 !important;
                }
            }
        }
    }

    /* =============================================================================
    Extra Small +
    ========================================================================== */ 
    @include media-breakpoint-up(xs) {

    } 

    /* =============================================================================
    Small +
    ========================================================================== */ 
    @include media-breakpoint-up(sm) {

    }


    /* =============================================================================
    Medium +
    ========================================================================== */
    @include media-breakpoint-up(md) {
        .home-banner--description {
            margin-top: 0;
        }

    } 

    /* =============================================================================
    Large +
    ========================================================================== */
    @include media-breakpoint-up(lg) {
    
    } 

    /* =============================================================================
    Extra Large +
    ========================================================================== */
    @include media-breakpoint-up(xl) {
    
    }
}