.block.capabilities-v2 {
	margin-top: 0;
	position:relative;

    overflow-x: hidden;

    @media screen and (min-width:767px) {
	    overflow-x: visible;
	}
}
.capabilities-v2--item {
	max-width: 850px;
}
ul.capabilities-v2--sub_list {
	list-style-type:none;
	padding-left:0!important;
	margin:38px 0 30px 0px;
	overflow: hidden;
	li {
		&:marker {
			display:none;
		}
		margin-bottom: 10px!important;
		font-weight: bold!important;
		font-size: 25px!important;
		line-height: 130%!important;
		position:relative;
		display:inline-block!important;
		margin-right:29px;
		&:before {
			content:'/';
			color:rgba(255, 255, 255, 0.38);
			padding:0 10px;
			width:29px;
			margin-left:-29px;
		}
		
	}
}
.capabilities-v2--item {
	display:none;
	&.active {
		display:block;
	}
}

.capabilities-v2--description {
	font-size: 16px;
	line-height: 150%;
	max-width: 585px;
}

.capabilities-v2--link {
	margin-top:50px;

    & > .wp-block-button__link {
        color: #FFFFFF;
        background-color: transparent;

        &:hover {
            color: #000000;
            background-color: var(--brand-color);
        }
    }
}

.capabilities-v2--title {
	line-height: 1.2em;
	font-weight: 700;
	display: inline-block;
    color: var(--brand-color);
}

.capabilities-v2--content {
	position:relative;
	z-index: 2;
}

ul.capabilities-v2--selector {
	margin-top: 30px!important;
	padding:0!important;
	list-style-type:none;
	@media screen and (max-width:766px) {
	    display: flex;
	    overflow-x: scroll;
	    width: 110%;
		-ms-overflow-style: none;
		scrollbar-width: none;
		margin: 0;
		height: 75px;
	    &::-webkit-scrollbar {
		  display: none;
		}
	}
	li {
		list-style-type:none;
		margin-bottom:5px!important;

		a {
			padding:8px 0!important;
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			display: inline-block;
			position:relative;
            
			@media screen and (max-width:766px) {
				white-space: nowrap;
				margin-right: 31px;
			}

			&.active {
				color: var(--brand-color);

			}

			&:hover {
				color: var(--brand-color);
			}
		}
	}
}


/**
 * Tiles stuff
 */


.tiles {
	--tiles-height: 52vw;
	height: var(--tiles-height);
	position: relative;
	overflow: hidden;
	margin-top: -65vw;
	
	&:after {
		content:'';
		position:absolute;
		top:-21vw;
		left:0;
		background:linear-gradient(180deg, rgba(0, 0, 0, 0.9) 65.1%, rgba(0, 0, 0, 0) 100%);
		width:100%;
		height:110vw;
		@media screen and (max-width:766px) {
			top: -43vw;
			height:100vw;
		}
	}
}

.tiles--columns-rotated {
	--tiles-height: 115vmax;
	@media screen and (max-width:766px) {
		--tiles-height: 66vmax;
		width: 130%;
	}
}

.tiles--columns {
	--tiles-height: 140vh;
}

.tiles--oneline {
	--tiles-height: 50vh;
	min-height: 400px;
	margin: 10vh 0;
}

.tiles--small {
	--tiles-height: 100px;
	margin: 10vh 0 0;
}

.tiles--fixed {
	--tiles-height: 65vw;
	margin-top: 25vh;
}

.tiles--perspective {
	overflow: visible;
	perspective: 1000px;
	--tiles-height: 500px;
}

.tiles__wrap {
	width: 150%;
	--tileswrap-height: var(--tiles-height);
	height: var(--tileswrap-height);
	position: absolute;
	left: 60%;
	top: 50%;
	transform: translate3d(-50%,-50%, 0);
	&.loading {
		.tiles__line {
			opacity: 0;
		}
	}
	.tiles__line {
		opacity: 1;
		transition:opacity 0.3s ease;
	}

}

.tiles--rotated .tiles__wrap {
	--tileswrap-height: calc(var(--tiles-height) * 1.8);
	transform: translate3d(-50%,-50%, 0) rotate(22.5deg);
}

.tiles--columns-rotated .tiles__wrap {
	width: 150%;
	display: flex;
	transform: translate3d(-50%,-50%,0) rotate(-22.5deg);
	left: 50%;
	top: 50%;
	align-items: center;
	justify-content: center;
}

.tiles--columns .tiles__wrap {
	width: 100%;
	display: flex;
	--tileswrap-height: calc(var(--tiles-height) * 1.4);
	transform: translate3d(0,-50%,0);
	left: 0;
	top: 50%;
}

.tiles--perspective .tiles__wrap {
	width: 200%;
    transform: translate3d(-50%,-50%,0) translateX(-25%) translateZ(-1200px) rotateX(75.5deg) rotateZ(12deg);
}

.tiles--fixed .tiles__wrap {
	width: 100%;
}

.tiles__line {
	display: flex;
}

.tiles--columns .tiles__line {
	width: 25%;
	padding: 0 1rem;
	display: block;
}

.tiles--columns-rotated .tiles__line {
	flex: none;
	padding: 0 0.5vw;
	display: block;
}
.tiles--columns-rotated .tiles__line:nth-child(1) {
	width: 24%;
}
.tiles--columns-rotated .tiles__line:nth-child(2) {
	width: 12%;
}
.tiles--columns-rotated .tiles__line:nth-child(3) {
	width: 24%;
}
.tiles--columns-rotated .tiles__line:nth-child(4) {
	width: 22%;
}
.tiles--columns-rotated .tiles__line:nth-child(5) {
	width: 18%;
}

.tiles--columns-rotated .tiles__line:nth-child(even) {
	margin-top: -160px;
}

.tiles--oneline .tiles__line {
	height: 100%;
}

.tiles--small .tiles__line {
	height: 100%;
}

.tiles__line-img {
	--tile-margin: 1vw;
	flex: none;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: auto;
	margin: var(--tile-margin);
	background-size: cover;
	background-position: 50% 50%;
	img {
		width:100%;
	}
}

.tiles--perspective .tiles__line-img {
	backface-visibility: hidden;
	outline: 1px solid transparent;
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc(16.666vw * 1.3);
}

.tiles--oneline .tiles__line-img {
	--tile-margin: 1vw;
	margin: 0 var(--tile-margin);
	width: calc(25% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--small .tiles__line-img {
	--tile-margin: 5px;
	margin: 0 var(--tile-margin);
	width: calc(12.5% - var(--tile-margin) * 2);
	height: 100%;
}

.tiles--rotated .tiles__line-img {
	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc( var(--tileswrap-height) / 5 - (4 * var(--tile-margin) / 2));
}

.tiles--columns-rotated .tiles__line-img {
	--tile-margin: 1vw 0;
	width: 100%;
}

.tiles--columns .tiles__line-img {
	--tile-margin: 2rem 0;
	width: 100%;
	height: calc(25vw * 1.3);
}

.tiles--fixed .tiles__line-img {
	--tile-margin: 10px;
	-webkit-filter: brightness(0.8);
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: auto;
}

.tiles--darker .tiles__line-img {
	opacity: 1;
}

.tiles__title {
	position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10vw;
	padding: 3rem;
	margin: 0;
	line-height: 0.8;
	font-family: span, serif;
	font-weight: 700;
	font-style: normal;
}

.tiles__title--alt {
	font-size: 9vw;
	font-size: clamp(2rem, 9vw, 7.25rem);
}

.tiles__title--intro {
	padding-top: 10vh;
	align-items: flex-start;
	justify-content: flex-start;
}

.tiles__title--right {
	justify-content: flex-end;
}

.tiles__title--left {
	justify-content: flex-start;
}

.tiles__title--full {
	height: 100%;
}



@media screen and (min-width: 53em) {

	
	.tiles--columns-rotated .tiles__line:nth-child(even) {
		//margin-top: -20vmax;
	}
	
	
}
