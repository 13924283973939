@use 'sass:map';

/**
 * Global
 */
:root {
    --bg: #000000;
    --color: #FFFFFF;
    --brand-color: #{map.get($theme-colors, 'citrus')};
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $font;
  min-height: 100%;
  overflow-x: hidden;
  position:relative;
  color: var(--color);
  background-color: var(--bg);
  transition: color, background-color 0.3s ease-in-out;

  &.init-home {
    position:fixed;
    width:100%;
    .header--brand-col {
      opacity: 1!important;
    }
    .block:not(.home-banner) {
      //opacity:0;
    }
  }

  a:hover {
    color: inherit;
  }
}

body:not(.admin-bar) {
    @media screen and (max-width:1024px) {
      cursor: auto;
    }
    // cursor: none;
}
body.admin-bar {
    #app {
        @media screen and (max-width:1024px) {
          cursor: normal;
        }
        // cursor: none;
    }
}

#app {
    position:relative;

    &:has(.block.home-banner) {
        header.header {
            position: fixed;
        }
    }
}

img {
  max-width:100%;
  height:auto;
}

main.main {
  padding-top: calc(198px + 5vw);
  padding-bottom: 5vw;
  position:relative;
  z-index: 1;
  .single-work & {
    margin-top: 0;
    padding-top: 0;
  }
}

footer.content-info {
  position:relative;
  z-index: 1;
}

.container-fluid {
  padding: 0 12%;
  &.media-container {
    padding:0 7%!important;
    &.full-width {
      padding:0!important;
    }
  }
  &.full-width {
    padding:0;
    overflow-x:hidden;
  }
}

/**
 * GRID STUFF
 */

.row {
  margin-left: -3.5%!important;
  margin-right: -3.5%!important;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  padding-left:3.5%!important;
  padding-right: 3.5%!important;
}


.mobile-only {
  display: none;
  @media screen and (max-width:1024px) {
    display: block;
  }
}
.desktop-only {
  display: block;
  @media screen and (max-width:1024px) {
    display: none;
  }
}
