body {
    --gf-border-color: #FFF;
    --gf-button-text: #FFF;
    --btn-border-color: #FFF;

	transition: background-color 0.5s ease;
	h1,h2,h3,h4,h5,h6,p,li,a,span,td {
		transition:color 0.3s ease;
	}

    .brand svg .crew,
    .brand svg .accent {
        fill: var(--color);
    }
    .brand svg .tagline {
        fill:rgba(var(--color),0.5);
    }
    h1,h2,h3,h4,h5,h6,p,a,td,.split-text {
        color: var(--color);
    }
    header a {
        color: var(--color);

        &:hover {
            color: var(--color);
        }
    }
    &.page-accent-none {
        .brand svg .accent {
            fill: var(--color);
        }
        .header--menu-dot {
            background-color: var(--color);
        }
    }

    .header--menu-title {
        color: var(--color);
    }
    .header--menu-wrap .primary-navigation ul a:before {
        background-color: var(--color);
    }

	/**
	 * Background Color
	 */
	&.bgc--intial-black {
		--bg: #000000;
        --color: #FFFFFF;
	}

	&.bgc--intial-white {
		--bg: #FFFFFF;
        --color: #000000;
	}
}