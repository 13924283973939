.block.posts-nav {
	margin-top: 4%;
	font-size:16px;
	line-height: 18px;

	a {
		text-decoration: underline;
		text-decoration-color: transparent;
		transition: text-decoration-color 200ms ease-in;

		&:hover {
			text-decoration-color: currentColor;
		}
	}

	.single-work--posts_nav__next {
		text-align:right;
	}

	.posts_nav--link_upper {
		margin-bottom: 12px;
	}
	.posts_nav--link_upper,
	.posts_nav--link_lower {
		font-weight:bold;
	}

	@media screen and (max-width:1024px)  {
		.posts_nav--link_lower {
			display:none;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}