ul.footer--social {
	padding:0;
	list-style-type:none;
	display:flex;
	margin-left:-5px;
	margin-right:-5px;
	margin-bottom: 0;
	align-items:flex-end;

	li {
		padding:0 5px;

		&:last-child a {
			font-size: 25px;
			line-height: 32px;
			font-weight:bold;
		}

		svg {
            width: clamp(24px, 2.2vw, 32px);
            
			path {
				transition: fill 0.3s ease;
			}
		}

		.social-link {
			&:hover svg path {
				fill: rgb(0 0 0 / 0.8);
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}
