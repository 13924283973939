.block.related {
	.row {
		margin-left:-40px;
		margin-right:-40px;
	}

	.related--col {
		margin-bottom: 100px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.related--item {
		position:relative;
		height:100%;
		padding-top:75px;
		display: flex;
    	align-items: flex-end;
		&:hover {
			.related--item__thumbnail {
				//clip-path: inset(0 0 0 0);
			}
		}
	}

	.related--item__content {
		
	}

	.related--item__thumbnail {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		clip-path: inset(0 0 85% 0);
		transition:clip-path 0.5s ease;
		img {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			object-fit:cover;
			object-position:center;
		}
	}

	.related--item__title {
		display: block;
	}
	.related--item__meta {
		display: block;
		padding:0;
		margin:30px 0 0 0;
		list-style-type:none;
		li {
			display: inline-block;
			font-weight: bold;
			& + li {
				&:before {
					content:'/ ';
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}