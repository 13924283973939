.header--menu-wrap {
	transition: opacity 0.3s ease;
	.init-home & {
		opacity:0;
	}

	&:hover {
		.primary-navigation {
			opacity: 1;
			visibility: visible;
			transition-delay: 0s, 0s;
			transform: translateY(0px);
			ul {
				padding:20px 0;
				li {
					opacity: 1;
					transform:translateX(0px);
				}
			}
		}
		.header--menu-title {
			opacity: 0;
			transform:translateX(10px);
		}
		.header--menu-dot {
			opacity: 0;
			transform:translateX(10px);
		}	
	}
	.header--menu-title_wrap {
		position: absolute;
		right:calc(5% + 14px);
		top:50%;
		transform:translateY(-50%);
		display: inline-flex;
		flex-wrap:nowrap;
	}
	.header--menu-title {
		font-weight:700;
		opacity: 1;
		transform:translateX(0);
		transition: transform 0.3s ease, opacity 0.3s ease, color 0.3s ease;
	}
	.header--menu-dots {
		margin-left:13px;
		display: flex;
		align-items:center;
		justify-content:space-around;
		width:18px;
	}
	.header--menu-dot {
		width:4px;
		height: 4px;
		border-radius:50%;
		margin-top: 2px;
		transition:all 0.3s ease;
		opacity: 1;
		transform:translateX(0);
		&.dot-1 {
			transition-delay:0.2s;
		}
		&.dot-2 {
			transition-delay:0.1s;
		}
		&.dot-3 {
			transition-delay:0s;
		}
	}
	.primary-navigation {
		//display: none;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {		

		.primary-navigation {
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.2s ease 0s, visibility 0s ease 0.1s, transform 0.2s ease 0.1s;
			a {
				font-size: 18px;
				line-height: 20px;
				font-weight:700;
			}

			ul {
				justify-content:flex-end;
				li {
					&:nth-child(1) {transition-delay:0.3s;}
					&:nth-child(2) {transition-delay:0.25s;}
					&:nth-child(3) {transition-delay:0.2s;}
					&:nth-child(4) {transition-delay:0.15s;}
					&:nth-child(5) {transition-delay:0.1s;}
					&:nth-child(6) {transition-delay:0.05s;}
					&:nth-child(7) {transition-delay:0s;}
					
					transition: opacity 0.6s ease, transform 0.2s ease;
					opacity: 0;
					transform:translateX(20px);
					display: block;
					
				}
				
				a {
					padding-left: 1em;
					transition-delay:0s;
					position:relative;
					&:hover {
						text-decoration: none;
						transition:color 0.3s ease;
					}
					&:hover {
						&:before {
							width:calc(100% - 1em)
						}
					}
					&:before {
						content:'';
						position:absolute;
						width:0;
						bottom:-5px;
						left:calc(50% + .5em);
						transform:translateX(-50%);
						height:2px;
						background-color: white;
						display: block;
						transition:all 0.3s ease;
					}
				}
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}