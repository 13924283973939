@font-face {
  font-family: 'Helvetica Nueue';
  src: url('../fonts/HelveticaNeue.eot');
  src: url('../fonts/HelveticaNeue.woff') format('woff'),
    url('../fonts/HelveticaNeue.ttf') format('truetype'),
    url('../fonts/HelveticaNeue.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
/** Bold **/
@font-face {
  font-family: 'Helvetica Nueue';
  src: url('../fonts/HelveticaNeue-Bold.eot') format('embedded-opentype');
  src: url('../fonts/HelveticaNeue-Bold.woff') format('woff'),
    url('../fonts/HelveticaNeue-Bold.ttf') format('truetype'),
    url('../fonts/HelveticaNeue-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/** Semibold **/
@font-face {
  font-family: 'Helvetica Nueue';
  src: url('../fonts/HelveticaNeue-Roman.eot');
  src: local('☺'), url('../fonts/HelveticaNeue-Roman.woff') format('woff'),
    url('../fonts/HelveticaNeue-Roman.ttf') format('truetype'),
    url('../fonts/HelveticaNeue-Roman.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
