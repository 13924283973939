.block {
	margin-top: 7%;
	margin-bottom: 7%;
	&:first-child {
		margin-top: 0;
	}
}

.block-no-margin {
  margin-top : 0 !important;
  margin-bottom: 0 !important;
}
