body.single-work {
    --bg: #ffffff;
    --color: #000000;
    
	.brand svg .tagline {
		display: none!important;
	}
	header.single-work--header {
		position: relative;
		height:100vh;
		min-height:360px;
		img {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
		    object-position: center;
		}
	}

	.single-work--intro__meta {
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		margin-top:20px;
		tr:last-child {
			td {
				padding-bottom:0;
			}
		}
		td {
			vertical-align: top;
			padding-bottom: 20px;
			&:first-child {
				width:96px;
			}
		}

		ul {
			padding:0;
			margin:0;
			list-style-type:none;
			li {
				padding-bottom: 5px;
				margin-bottom: 0;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.single-work--intro__meta {
			margin-top: 0;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}