.block.careers {
    .careers--empty {
        display: flex;
        flex-wrap: wrap;
        gap: clamp(2rem, 10vw, 10rem);

        .careers--empty__message {
            flex: 1 1 0%;
        }

        .careers--empty__apply {
            white-space: nowrap;
            flex: 0 0 min-content;
        }
    }

    .careers__listings {
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        gap: clamp(2.5rem, 7vw, 6.25rem) clamp(2rem, 5vw, 5rem);

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }

        .careers__job {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            & > * {
                margin: 0 !important;
            }
        }

        .careers__job-tags {
            padding: 0;
            list-style: none;
            display: flex;
            gap: 14px;
            margin: 0;
            color: rgb(255 255 255 / 0.4);

            li {
                margin-bottom: 0;
                list-style-type: none;
                font-size: calc( 14px + (16 - 14) * (100vw - 400px) / (1440 - 400) );
            }
        }

        .careers__job-excerpt {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            font-weight: 400;

            p {
                margin: 0;
            }
        }

        .careers__job-link {
            display: inline-flex;
            align-items: center;
            padding: 0.75rem;
            gap: 0.5rem;
            font-size: calc( 17px + (20 - 17) * (100vw - 400px) / (1440 - 400) );
            background-color: transparent;
            transition: color, background-color 300ms ease-in;

            svg {
                path {
                    fill: white;
                    transition: fill 300ms ease-in;
                }
            }

            &:hover {
                color: #000;
                background-color: #FFF;

                svg {
                    path {
                        fill: #000;
                    }
                }
            }
        }
    }
}