.split-text {
  .line {
    overflow: hidden;
    display:inline!important;
    &:last-child .word {
      animation-delay: 1.1s;
    }
  }

  .word {
    opacity: 0;
    animation: slide-in 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.remove-top-margin {
  margin-top:0!important;
}
.remove-bottom-margin {
  margin-bottom:0!important;
}

.white-space-headings {
  h1,h2,h3,h4,h5,h6 {
    white-space:nowrap;
  }
}

@media screen and (max-width:767px) {
  .full-width-mobile {
      flex-basis:100%;
  }
  .add-top-spacing-mobile {
    margin-top:20px;
  }
}

.mb-40 {
  margin-bottom:40px;
}

/**
 * Homepage capabilites gutenberg columns
 */

@media screen and (max-width:992px) {
  .wp-block-column.home-capa-force-column-left {
    flex-basis:50%!important;
  }
  .wp-block-column.home-capa-force-column-right {
    padding-left:20px;
    flex-basis:35%!important;
  }
}