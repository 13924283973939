.block.about-crew {
	padding-bottom: 200px;

	svg path {
		transition:fill 0.5s ease;
	}
	.about-crew--scene {
		--translate-x-amount: 0;

		display:flex;
		align-items:flex-end;
		padding-bottom: 100px;
		transition: transform 0.5s ease;
		transform-origin: left top;

		&.about-crew--active {
			transform: translateX(var(--translate-x-amount)) scale(2);

			@include media-breakpoint-up(md) {
				transform: none;
				transform-origin: initial;
			}
		}
	}

	.letter_c,
	.letter_r,
	.letter_e,
	.letter_w,
	.impact {
		transition: all 0.5s ease;
		opacity:1;
	}

	.about-crew--info {
		--translate-info: 0;

		transition: visibility 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
		transform: translateX(var(--translate-info)) scale(0.5);
		transform-origin: left;

		@include media-breakpoint-up(md) {
			transform: scale(1);
			transform-origin: initial;
		}
	}

	.c-active {

		.about-crew--item_wrap {
			&.letter_c {
				svg path {
					fill: map-get($theme-colors, 'yellow');
				}
				.about-crew--info {
					--translate-info:-20px;

					opacity: 1;
					visibility: visible;

					@include media-breakpoint-up(md) {
						transform: translate(0vw, -25%);
					}
				}
			}

			&.letter_r,
			&.letter_e,
			&.letter_w,
			&.impact {
				opacity:0.1;
			}
			&.letter_e {
				transition-delay:0.1s
			}
			&.letter_w {
				transition-delay:0.2s
			}
			&.impact {
				transition-delay:0.3s
			}
		}

	}

	.r-active {
		--translate-x-amount: -30%;

		.about-crew--item_wrap {
			&.letter_r {
				opacity:1;
				

				svg path {
					fill: var(--brand-color);
				}
				.about-crew--info {
					--translate-info:-20px;

					opacity: 1;
					visibility: visible;

					@include media-breakpoint-up(md) {
						transform: translate(-23vw, -50%);
					}
				}

				@include media-breakpoint-up(md) {
					transform:translateX(0);
				}
			}
			&.letter_c,
			&.letter_e,
			&.letter_w,
			&.impact {
				opacity:0.1;
			}
		}
	}


	.e-active {
		--translate-x-amount: -45%;

		.about-crew--item_wrap {
			&.letter_e {
				opacity:1;

				svg path {
					fill: map-get($theme-colors, 'orange');
				}
				.about-crew--info {
					--translate-info:-20px;

					opacity: 1;
					visibility: visible;

					@include media-breakpoint-up(md) {
						transform: translate(22vw, -50%);
					}
				}

				@include media-breakpoint-up(md) {
					transform:translateX(0);
				}
			}
			&.letter_c,
			&.letter_r,
			&.letter_w,
			&.impact {
				opacity:0.1;
			}
		}
	}

	.w-active {
		--translate-x-amount: -60%;

		.about-crew--item_wrap {
			&.letter_w {
				opacity:1;
				
				svg path {
					fill: map-get($theme-colors, 'purple');
				}
				.about-crew--info {
					--translate-info:-20px;

					opacity: 1;
					visibility: visible;
					
					@include media-breakpoint-up(md) {
						transform: translate(-31vw, -50%);
					}
				}

				@include media-breakpoint-up(md) {
					transform:translateX(0);
				}
			}
			&.letter_c,
			&.letter_r,
			&.letter_e,
			&.impact {
				opacity:0.1;
			}
		}
	}

	.impact-active {
		--translate-x-amount: -73%;

		.about-crew--item_wrap {
			&.impact {
				opacity:1;

				svg path {
					fill: url(#impact_gradient);
				}
				.about-crew--info {
					--translate-info:-20px;

					opacity: 1;
					visibility: visible;

					@media screen and (min-width:768px) {
						transform: translate(-39vw, -50%);
						min-width: 30vw;
					}
				}

				@include media-breakpoint-up(md) {
					transform:translateX(0);
				}
			}
			&.letter_c,
			&.letter_r,
			&.letter_e,
			&.letter_w {
				opacity:0.1;
			}
		}
	}


	.about-crew--item_wrap {

		@include media-breakpoint-up(md) {
			position:relative;
		}

		&.yellow {

		}
		&.pink {}
		&.orange {}
		&.purple {}

		&.letter_c {
			svg {
				width:19.5vw;
			}

			.about-crew--info {
				top: 40%;
				left: 20px;
				min-width: 80vw;

				@include media-breakpoint-up(md) {
					position: absolute;
					top: 50%;
					left: auto;
					transform: translate(-5vw, -25%);
					margin-left: 27vw;
					min-width: 28vw;
				}

			    h3 {
			    	color:map-get($theme-colors, 'yellow');
			    }
			}

		}
		&.letter_r {
			margin-left:0.5vw;
			svg {
				width:10.5vw;
			}
			.about-crew--info {
				top: 40%;
				left: calc(15% + 20px);
				min-width: 80vw;

				@include media-breakpoint-up(md) {
					position: absolute;
					top: 50%;
					left: auto;
					transform: translate(-28vw, -50%);
					margin-left: 40vw;
					min-width: 28vw;
				}

			    h3 {
			    	color:var(--brand-color);
			    }
			}
		}
		&.letter_e {
			margin-left:-0.5vw;
			svg {
				width:15.5vw;
			}
			.about-crew--info {
				top: 36%;
				left: calc(22% + 20px);
				min-width: 80vw;
				
				@include media-breakpoint-up(md) {
					position: absolute;
					top: 50%;
					left: auto;
					transform: translate(17vw, -50%);
					margin-left: 0vw;
					min-width: 28vw;
				}
			    h3 {
			    	color:map-get($theme-colors, 'orange');
			    }
			}
		}
		&.letter_w {
			svg {
				margin-left:-1.5vw;
				width:24.5vw;
			}
			.about-crew--info {
				top: 43%;
				left: calc(30% + 20px);
				min-width: 80vw;

				@include media-breakpoint-up(md) {
					position: absolute;
					top: 50%;
					left: auto;
					transform: translate(-28vw, -50%);
					// margin-left: -20vw;
					// min-width: 40vw;
					margin-left: 0vw;
					min-width: 28vw;
				}
			    @media screen and (min-width:768px){
			    }
			    h3 {
			    	color:map-get($theme-colors, 'purple');
			    }
			}
		}
		&.impact {
			svg {
				margin-bottom: 15.75vw;
				width:5.5vw;
			}
			.about-crew--info {
				top: 43%;
				left: calc(36% + 20px);
				min-width: 80vw;

				@include media-breakpoint-up(md) {
					position: absolute;
					top: 50%;
					transform: translate(-28vw, -50%);
					margin-left: 0vw;
					min-width: 28vw;
				}
			    h3 {
			    	font-style:italic;
			    	background:$gradient-1;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				    display: inline-block;
				    padding-right: 20px;
			    }
			}
		}
	}

	.about-crew--letter {
		svg {}
	}

	.about-crew--info {
		opacity: 0;
		visibility: hidden;
		position:absolute
	}

	.about-crew--title {
		
		h3 {
			margin-bottom: 20px;
		}
	}

	.about-crew--description {
		
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {
		//
	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.about-crew--scene {
			padding-bottom: 0;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}