.block.logo-gallery {
	.logo-gallery--link_desktop {
		display: none;
	}
	.logo-gallery--link_mobile {
		display: block;
		margin-top: 7%;
	}
	.row {
		margin-left: -2%!important;
		margin-right: -2%!important;
	}

	.logo-gallery--title_col {}
	.logo-gallery--link_col {
		text-align:right;
	}

	.logo-gallery--logos {
		margin-top: 50px;
	}
	.col-md-6 {
		padding-left: 2%!important;
		padding-right: 2%!important;
	}
	.logo-gallery--logos_col {
		text-align: center;
		min-width:15%;
		padding-left: 2%!important;
		padding-right: 2%!important;
		min-width: 8em;
		max-width: 14em;
		@media screen and (max-width:1024px) {
			max-width: 10em;
		}
		img {
			padding:5px;
			opacity: 0.8;
			transition:opacity 0.3s ease;
			&:hover {
				//opacity: 1;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.logo-gallery--link_desktop {
			display: block;
		}
		.logo-gallery--link_mobile {
			display: none;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}