.block.job-description {
    min-height: 200px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: max(2.5rem, 5%);
    padding-bottom: max(2.5rem, 5%);
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: var(--gradient);
    
    p, li {
        font-size: calc( 17px + (20 - 17) * (100vw - 400px) / (1440 - 400) );
        font-weight: 400;
    }

    @include media-breakpoint-up(md) {
        ul, ol {
            columns: 2;
        }
    }
}