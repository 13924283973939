.menu-open header.header.headroom--unpinned:not(.headroom--top) {
	mix-blend-mode:normal!important;
}

header.header {
	height:100px;
	width:100%;
	position: fixed;
	left:0;
	top:0;
	z-index: 102;
	transition: height 0.3s ease, background-color 0.3s ease;

	body.home & {
		position: sticky;
	}

	@media screen and (min-width:1025px) {
        body.cta-active &:hover,
		&:not(.headroom--top):hover {
            --color: #FFFFFF;

			mix-blend-mode:normal!important;
			background-color:rgba(0, 0, 0, 0.85);
			
			.header--brand-col {
				opacity:1!important;
                position: relative;
                z-index: 100;
			}
		}
	}
	
	&.headroom--bottom:not(:hover) {
		.has-page-cta & {
			.header--brand-col {
				opacity: 1!important;
			}

			.header--menu-title {
				color: black;
			}
		}
	}

	&.headroom--pinned {
		.header--brand-col {
			opacity: 1;
		}
	}

	&.headroom--unpinned:not(.headroom--top) {
		.header--brand-col {
			opacity:0;

			.menu-open &,
            .cta-active & {
				opacity: 1 !important;
			}
		}
	}

	.admin-bar & {
		top: 32px;
	}
	& > .container-fluid {
		height:100%;
		.row {
			height:100%;
		}
	}
	.header--brand-col,
	.header--menu-col {
		position: relative;
		padding-left:3.5%;
		padding-right: 3.5%;
		box-sizing:border-box;
		transition: opacity 0.3s ease;
	}
	.header--brand-col {
		flex: 0;

		.brand {
			display: block;
			width: 100px;

			svg {
				max-width: 100%;
				max-height: 100%;
			}

			@media screen and (min-width:768px) {
				width: 110px;
			}
		}
	}
	.header--menu-col {
		flex: 0 0 calc(100% - 250px);
		max-width: calc(100% - 250px);
		@media screen and (min-width:1025px) {
		    flex: 0 0 calc(100% - 375px);
		    max-width: calc(100% - 375px);
		}
	}

	.brand svg path {
		transition:fill 0.3s ease;
	}

	svg .tagline {
		transition:all 0.3s ease;
		opacity: 1;
		transform:translateX(0);
		transition-delay:0.5s;
	}
	.shrink:not(.menu-open) & {
		svg .tagline {
			opacity: 0;
			transform:translateX(-50px);
		}
	}

	.header--menu-wrap {
		display: none;
	}

	.header--mobile-trigger {
		background-color: var(--color);
		width: 38px;
		height: 38px;
		border-radius: 50%;
		display: flex;
		align-items:center;
		justify-content:space-between;
		padding:0 10px;
		margin-left: auto;
		cursor: pointer;
        
		.header--menu-dot {
			background-color: var(--bg);
			width: 5px;
			height: 5px;
			border-radius: 50%;
			transition:transform 0.3s ease;
		}
		transition:all 0.3s ease;
		position:relative;
	
		&:before,
		&:after {
			content:'';
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			transition:all 0.3s ease;
            background-color: var(--color);
		}
		&:before {
			width:0;
			height:2px;
		}
		&:after {
			height:0;
			width: 2px;
		}
		.menu-open & {
			background-color: transparent;
			transform: rotate(45deg);
			&:before {
				width:90%;
			}
			&:after {
				height:90%;
			}
			.header--menu-dot {
				transform: scale(0);
			}
		}
	}
	@media screen and (min-width:1025px) {
		.header--menu-wrap {
			display: block;
		}
		.header--mobile-trigger {
			display: none;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		margin-bottom: 50px;
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}