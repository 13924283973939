.block.logo-banner {
  background: var(--bg);
  color: var(--color);
  padding: 20px 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  .block-title {
    text-align: center;
  }

  .logo--container{
    width: 200px !important;

    img{
      width: 100%;
      height: auto;
    }
  }

  .slick-track{
    display: flex;
    align-items: center;
  }

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}

}
