p {
    font-size: calc( 17px + (20 - 17) * (100vw - 400px) / (1440 - 400) );

    &.is-style-large {
        font-size: calc( 19px + (23 - 19) * (100vw - 400px) / (1440 - 400) );
    }

    &.is-style-small {
        font-size: calc( 14px + (16 - 14) * (100vw - 400px) / (1440 - 400) );
    }
}

body {

	font-size: calc( 17px + (20 - 17) * (100vw - 400px) / (1440 - 400) );


	// body formats
	.body-1 {
		font-size: calc( 19px + (23 - 19) * (100vw - 400px) / (1440 - 400) );
	}
	.body-2 {
		font-size: calc( 17px + (20 - 17) * (100vw - 400px) / (1440 - 400) );
	}
	.body-3 {
		font-size: calc( 14px + (16 - 14) * (100vw - 400px) / (1440 - 400) );
	}
	.footnote {
		font-size: 10px;
		line-height: 16.6px;
	}

	a {
	  color: var(--brand-color);
	  // cursor: none;
	  @media screen and (max-width:1024px) {
        cursor: auto;
      }

	  &:hover {
	  	text-decoration:none;
	  }
	}

	.wysiwyg {
		h1,h2,h3,h4,h5,h6 {
			margin-bottom: 1em;
			margin-top: 1em;
		}
	}

	h1,h2,h3,h4,h5,h6 {
		font-weight:bold;
		line-height:1em;
		font-family: $font;
		&.is-style-tall-line-height {
			line-height: 1.2em;
		}

        &.gradient-1 {
            --gradient: #{$gradient-1};
        }
        &.gradient-2 {
            --gradient: #{$gradient-2};
        }
        &.gradient-3 {
            --gradient: #{$gradient-3};
        }
        &.gradient-4 {
            --gradient: #{$gradient-4};
        }
        &.gradient-5 {
            --gradient: #{$gradient-5};
        }

        &.has-citrus-color {
            color: var(--brand-color);
        }
	}

	h1 {
		font-size: calc( 70px + (90 - 70) * (100vw - 400px) / (1440 - 400) );
		@media screen and (max-width:600px) {
			font-size: 60px;
		}
	}
	h2 {
		font-size: calc( 40px + (65 - 40) * (100vw - 400px) / (1440 - 400) );

	}
	h3 {
		font-size: calc( 30px + (45 - 30) * (100vw - 400px) / (1440 - 400) );
	}
	h4 {
		font-size: calc( 24px + (25 - 24) * (100vw - 400px) / (1440 - 400) );
		line-height: 31px;
	}
	h5 {
		font-size: calc( 15px + (16 - 15) * (100vw - 400px) / (1440 - 400) );
		line-height: 20px;
	}
	h6 {
		font-size: 13px;
		line-height: 20px;
	}

	label {
		font-size: 12px;
		line-height: 12px;
		letter-spacing: 0.22em;
		text-transform: uppercase;
	}

	/**
	 * Lists
	 */

	main.main {
		ul,ol {
			&.is-style-columnize {
				@media screen and (min-width:1025px) {
					columns:2;
				}
			}

			li {
				font-size: 16px;
				margin-bottom:20px;
				font-weight:bold;

                &::marker {
                    color:var(--brand-color);
                    font-size: .8em;
                }

                & > :where(ul,ol) {
                    margin-top: 20px;
                }
			}
		}

        .single--content {
            ul li {
                list-style-type: disc;
            }
        }

		ol {
		    counter-reset: item;
		    list-style-type: none;

			& > li {
				position:relative;
				display: block;
				margin-bottom:20px;
				font-size: 16px;
				font-weight:bold;
				padding-left: 20px;

				&:before {
				    content: counter(item) "  ";
				    counter-increment: item;
				    color: var(--brand-color);
					position: absolute;
					left: -21px;
					font-weight: bold;
				}
			}

            & > li ol > li:before {
                content: counters(item) " ";
            }
		}

    ol.wp-block-list[class],
    ul.wp-block-list[class] {
      padding-left: 20px;
    }
	}
	/**
	 * Pull Quote
	 */

	.wp-block-pullquote {
		blockquote {
			position:relative;
			&:before {
				content:'“';
				position:absolute;
				left:-20px;
			}
		}
		&.is-style-default {
			font-weight: bold;
			font-size: 36px;
			line-height: 105%;
			cite {
				margin-top:20px;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				font-style:normal;
			}
		}
		&.is-style-pull-quote2 {
			font-weight: bold;
			font-size: 27px;
			line-height: 125%;
			cite {
				margin-top:20px;
				font-weight: bold;
				font-size: 13px;
				line-height: 20px;
				font-style:normal;
			}
		}
	}

	/**
	 * Pre
	 */
	pre {
		&.wp-block-verse {
			white-space: pre-wrap;
			font-family: inherit;
			background-color:rgba(0, 0, 0, 0.05);
			padding:15px;
		}
		&.wp-block-code,
		&.wp-block-preformatted {
			white-space: pre-wrap;
			background-color:rgba(0, 0, 0, 0.05);
			padding:15px;
		}
	}

	/**
	 * Table
	 */
	figure {
		&.wp-block-table {
			table {
				table-layout:fixed;
				border-collapse:collapse;
				border:1px solid black;
				width:100%;
				tr {
					&:last-child {
						td {
							border-bottom:none;
						}
					}
				}
				td {
					border-right:1px solid black;
					border-bottom:1px solid;
					padding:10px;
					&:last-child {
						border-right:none;
					}
				}
				&.is-style-stripes {

				}
			}
			&.is-style-stripes {
				table {
					border:none;
					tr {
						&:nth-child(even) {
							background-color:rgba(0, 0, 0, 0.05);
						}
					}
					td {
						border:none;
					}
				}
			}
		}
	}

	/**
	 * Cover
	 */

	.wp-block-cover {
		height: 39vw;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		&.has-background-dim {
			position:relative;
			&:before {
				content:'';
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color:rgba(0, 0, 0, 0.5);
			}
		}
		.wp-block-cover__inner-container {
			padding:20px;
			color:white;
			position:relative;
			* {
				color:white;
				position:relative;
			}
		}
	}


	// custom colors gutenberg

	.has-yellow-color {
		color: var(--brand-color) !important;
	}
	.has-pink-color {
		color: var(--brand-color) !important;
	}
	.has-orange-color {
		color: var(--brand-color) !important;
	}
	.has-purple-color {
		color: var(--brand-color) !important;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
