.work-grid--item {
    .work-grid--item_image-opaque {
        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background: rgba(0, 0, 0, 0.75);
            z-index: 10;
            opacity: 0;
            transition:opacity 0.3s ease;
        }
    }
    .work-grid--meta {
        position: relative;
        z-index: 20;
    }

    &:hover {
        .work-grid--item_image-opaque {
            &:before {
                opacity: 1;
            }
        }
    }
}
@media screen and (max-width:1024px) {
	.work-grid--meta {
		display: none;
	}
	.work-grid--item_image-opaque {
		position:absolute;
		width:100%;
		height: 100%;
		top:0;
		left:0;
		transition:opacity 0.3s ease;
		background: {
			size:cover;
			position:center;
		}
		opacity: 1;
	}
}
@media screen and (min-width:1025px) {
	.work-grid--item {
		position:relative;
		display: flex;
		align-items: flex-end;
		padding: 40px;

        &:hover {
            .work-grid--item_image-opaque {
                
            }
        }

		&.mouse-left {
			.work-grid--item_image {
				clip-path:circle(0px at 50% 50%)!important;
				//transition:clip-path 0.5s ease;
			}
		}
		&.grid--animating {
			.work-grid--item_image {
				//transition:clip-path 0.2s ease;
			}
		}
		&.mouse-entered {
			.work-grid--item_image-opaque {
				opacity:0.15;
			}		
		}
	}
	.work-grid--item_image-opaque {
		position:absolute;
		width:100%;
		height: 100%;
		top:0;
		left:0;
		transition:opacity 0.3s ease;
		background: {
			size:cover;
			position:center;
		}
		opacity: 1;
	}
	.work-grid--item_image {
		clip-path:circle(0px at 50% 50%);
		position:absolute;
		width:100%;
		height: 100%;
		top:0;
		left:0;
		background: {
			size:cover;
			position:center;
		}
		&:after {
			content:'';
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background:linear-gradient(45deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 48%);
			background-blend-mode: multiply;
		}
	}

	.work-grid--item:hover {
		.work-grid--meta {
			transform:translateX(0px);
			opacity: 1;
		}
	}
	.work-grid--meta {
		max-width:350px;
		opacity:0;
		position:relative;
		transition:all 0.3s ease;
		transform:translateX(-30px);
		h6 {
			font-weight:bold;
			font-size: 13px;
			line-height: 20px;
		}
		h4 {
			font-weight: bold;
		}
		span {
			font-weight:bold;
			font-size: 14px;
			line-height:18px;
			svg {
				transform:translateX(0);
				transition:transform 0.3s ease;
			}
			&:hover {
				svg {
					transform:translateX(10px);
				}
			}
		}
	}
}	