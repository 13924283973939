.block.contact-form {
	margin-bottom: 0;

  &:last-child {
      margin-top: 15%;
  }

	.contact-form--title {
		margin-bottom: 30px;
	}
	.contact-form--challenges_title {
		margin-bottom: 75px;
		@media screen and (min-width:1025px) {
			max-width: 50vw;
		}
	}

	.contact-form--selector {
		min-height:395px;
		.row {
			padding-left: 3.5%;
			padding-right: 3.5%;
		}
		ul {
			margin:0;
			padding:0;
			list-style-type:none;
			margin-left: -30px;
			margin-right: -30px;

			li.contact-form--col {
				margin-bottom: 1%;
				padding-left: 0.5%!important;
				padding-right: 0.5%!important;

				a {
					width:100%;
					display: flex;
					align-items:center;
					height: 100%;
					background-color: white;
					transition:all 0.3s ease;
					text-align:center;
					text-align: center;
					justify-content:center;
					font-weight: bold;

					h4 {
						color:black!important;
						font-weight: bold;
						margin-bottom: 0;
						padding:16% 10%;
					}
					&:hover,
					&.active {
						background-color: black;
						h4 {
							color:white!important;
						}
					}
				}
			}
		}
	}

	.contact-form--wrapper {
		display:none;

        &:first-child {
            display: block;
        }

		label.gfield_label {
      margin-bottom: 5px;
      font-size: 20px;
		}

        textarea,
        select,
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
            border: 1px solid #C6C6C6!important;
            padding: 0.75rem 1rem !important;
            background-color: transparent;
            color: map-get($theme-colors, 'grey-2') !important;

            &:focus-within {
                outline: none !important;
                border: 1px solid var(--color) !important;
            }
        }

		textarea,
		select {
			width:calc(100% - 16px)!important;
			margin-bottom: 0!important;

		}
		input {
			width:calc(100% - 16px)!important;
			margin-bottom: 0!important;
		}
		div.ginput_complex input {
			width:100%!important;

		}
		textarea {
			max-height:100px;
		}
		.gf_textarea_field {
			margin-bottom: 0!important;
		}
		.fix-top-margin {
			margin-top: 7px!important;
		}

		.gform_footer {
		    text-align: right;
		    padding-right: 27px;
		}

		button[type="submit"] {
            transition: background-color 300ms ease-in;
			background-color: var(--brand-color);
			position: relative;
			width: 134px;
			position: relative;
			color: #000;
			border-width: 1px solid var(--brand-color);

			&:hover {
                color: var(--color);
                background-color: transparent;
			}
		}
	}

	.contact-form--nav {
		margin-top: 7%;
		ul {
			margin:0;
			padding:0;
			list-style-type: none;
			li {
				display:inline;
				position: relative;
				font-weight:bold;
				font-size: 16px;
				line-height: 20px;
				padding-bottom: 8px;
				& + li {
					margin-left: 20px;
				}
				&:before {
					content:'';
					position:absolute;
					bottom:0;
					left:0;
					width:0;
					transition: all 0.3s ease;
					height:2px;
					background-color:black;
				}
				&.active {
					&:before {
						width:100%;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
    .contact-form--wrapper {
      label.gfield_label {
        max-width: 300px;
      }
    }
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
