

/* Gutenberg Gallery Responsive CSS Fix */
.wp-block-gallery {
	ul.blocks-gallery-grid {
		padding:0;
		margin:0;
		padding:0;
		display: grid;
		grid-auto-flow: dense;
		grid-gap: 1em;
		list-style-type:none;
	}

	&.columns-6 {
		.blocks-gallery-grid {
	    	grid-template-columns: repeat( 6, 1fr );
	    }
	}
	&.columns-5 {
		.blocks-gallery-grid {
	    	grid-template-columns: repeat( 5, 1fr );
	    }
	}
	&.columns-4 {
		.blocks-gallery-grid {
	    	grid-template-columns: repeat( 4, 1fr );
	    }
	}
	&.columns-3 {
		.blocks-gallery-grid {
	    	grid-template-columns: repeat( 3, 1fr );
	    }
	}
	&.columns-2 {
		.blocks-gallery-grid {
	    	grid-template-columns: repeat( 2, 1fr );
	    }
	}

	@media only screen and (min-width: 450px) and (max-width: 550px) {

		&.columns-3, &.columns-4, &.columns-5, &.columns-6 {
			.blocks-gallery-grid {
	    		grid-template-columns: repeat( 3, 1fr);
	    	}
		}

	}

	@media only screen and (max-width: 450px) {

		&.columns-2, &.columns-3, &.columns-4, &.columns-5, &.columns-6 {
			.blocks-gallery-grid {
	    		grid-template-columns: repeat( 2, 1fr );
	    	}
		}
	}

	.blocks-gallery-item {
		margin: 0;
		padding: 0;
	}

}