.block.dynamic-work-grid {
	@media screen and (max-width:1024px) {
		&.double_1 {
			.work-grid--item:last-child {
				height:80vw;
			}
		}
		.dynamic-work-grid--block_container {
			position:relative;
			display:flex;
			flex-wrap:wrap;
		}
		.work-grid--item {
			height:50vw;
		}
		.work-grid--item,
		.Top-Left,
		.Top-Right,
		.Middle-Right,
		.Bottom-Left {
			position:relative;
			flex:0 0 100%;
			max-width:100%;
			margin-bottom:7vw;

			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}

            &.has-text {
                margin-bottom: 0;
            }
		}
		.Top-Left,
		.Bottom-Left,
		.Top-Right {
			height: 80vw;
		}
		.Top-Left,
		.Top-Right,
		.Middle-Right,
		.Bottom-Left {
			.work-grid--item {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				transition: all 0.3s ease;
				background-size: cover;
				background-position: center;
				opacity: 1;
			}
		}
	}
    &.triple_1 {
        .Bottom-Right {
            .work-grid--item {
                display: flex;
                align-items:center;

                h3 {
                    margin-bottom: 0;
                }
            }
        }
        
        .Top-Right{
            display: flex;
            align-items:center;

            & > h2 {
                margin-bottom: 0;
            }
        }
    }
	@media screen and (min-width:1025px) {
		.work-grid--item {
			height: 100%;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5%);
			display: none;
			h3 {
				height: 100%;
				display: flex;
				align-items: center;
				padding: 50px;
				background-color: var(--brand-color);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
		.work-grid--item.no-clip-transition:hover {
			.overlay {
				display: block;
				transition: none !important;
			}
		}

		.dynamic-work-grid--block_container {
			display: grid;
			grid-template-columns: repeat(5, 11.6vw);
		}
		&.single_1 {
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(3, 11.6vw);
				gap: 7vw;
			}
			.work-grid--item {
				grid-area: 1 / 1 / 4 / 6;
			}
			
		}
		&.double_1 {
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(2, 11.6vw);
				gap: 7vw;
			}
			a:first-child {
				grid-area: 1 / 1 / 3 / 4;
			}
			a:last-child {
				grid-area: 1 / 4 / 3 / 6;
			}
		
			
		}
		&.double_2 {
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(2, 11.6vw);
				gap: 7vw;
			}
			a:first-child {
				grid-area: 1 / 1 / 3 / 3;
			}
			a:last-child {
				grid-area: 1 / 3 / 3 / 6;
			}
		
		}
		&.triple_1 {
			
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(5, 11.6vw);
				gap: 7vw;
			}
			.Top-Left {
				margin-left: -7vw;
				grid-area: 1 / 1 / 4 / 3; 
			}
			.Top-Right {
				grid-area: 1 / 3 / 3 / 6;
				display: flex;
				align-items:center;
			}
			.Middle-Right {
				grid-area: 3 / 3 / 5 / 6;
			}
			.Bottom-Left {
				grid-area: 4 / 1 / 6 / 3; 
			}
			.Bottom-Right {
				grid-area: 5 / 3 / 6 / 6;
			}
		}
		&.triple_2 {
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(4, 11.6vw);
				gap: 7vw;		
			}

			.Top-Left {
				grid-area: 1 / 2 / 3 / 4; 
			}
			.Top-Right {
				grid-area: 1 / 4 / 4 / 6;
				margin-right:-7vw;
			}
			.Bottom-Left {
				grid-area: 3 / 1 / 5 / 4;
				margin-left:-7vw;
			}
			.Bottom-Right {
				grid-area: 4 / 4 / 5 / 6;
			}
		}

		&.text_1 {
			.dynamic-work-grid--block_container {
				grid-template-rows: repeat(2, 11.6vw);
				gap: 7vw;
			}
			h2 {
				grid-area: 1 / 2 / 3 / 5;
			}
			
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
