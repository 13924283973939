.block.posts-slider {

	@media screen and (max-width:1024px){
		.posts-slider--item_inner {
			flex-wrap:wrap;
			min-height:0!important;
		}
		.posts-slider--item_left,
		.posts-slider--item_right {
			flex: 0 0 100%!important;
			max-width:100%!important;
		}
		.posts-slider--item_right {
			height:50px;
			margin-bottom:20px;
		}
		.posts-slider--item {
			padding-left:0!important;
		}
		.slick-dots {
			top:auto!important;
			bottom:-50px;
			width:100%;
			text-align:center;
			li {
				display: inline-block!important;
			}
		}
	}

	position:relative;
	.posts-slider--slider {}

	.posts-slider--item_inner {
		display: flex;
		min-height:360px;
		width:100%;
		padding-bottom:40px;
		flex-direction:row-reverse;
	}
	.posts-slider--item_left {
		flex:0 0 66.66%;
		max-width:66.66%;
		h2 {
			margin-bottom:0.5em;
		}
		// .posts-slider--item_title a {
		// 	background-image: $gradient-1;
		// 	-webkit-background-clip: text;
		// 	background-clip: text;
		// 	background-size: 0 100%;

		// 	&:hover {
		// 		background-size: 100%;
		// 		-webkit-text-fill-color: transparent;
		// 	}
		// }

        .posts-slider--insights-title {
            margin-bottom: 20px;
        }
	}
	.posts-slider--item_right {
		flex:0 0 33.33%;
		max-width:33.33%;
		position:relative;
		&:hover {
			.posts-slider--item_image {
				clip-path:inset(0 0 0 0);
			}
		}
	}
	
	.posts-slider--item_image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		@media screen and (min-width:1025px){
			clip-path: inset(0 0 0 90%);
			transition:clip-path 0.5s ease;
		}
	}

	.posts-slider--item {
		padding-left: 100px;
	}
	.posts-slider--item_meta {
		list-style-type:none;
		padding:0;
		margin-bottom:50px;
		li {
			display: inline-block;
			body:not([data-entrancetext="black"]) & {
				color:map-get($theme-colors, 'grey-2');
			}

			a {
				font-weight:bold;
				body:not([data-entrancetext="black"]) & {
					color:map-get($theme-colors, 'grey-2');
				}
			}
		}
	}

	.slick-dots {
		position:absolute;
		left:0;
		top:0;
		padding:0;
		list-style-type:none;
		margin-top:-10px;
		li {
			display: block;
			padding:10px;
			.slick-button {
				border-radius:50%;
				margin:0 auto;		
				width:7px;
				height:7px;
				background-color:map-get($theme-colors, 'grey-2');
				transition: all 0.3s ease;
			}
			&:hover {
				.slick-button {
					background-color:white;
					transform:scale(4);
				}
			}
			& + li {
			}
			&.slick-active {
				&:hover {
					.slick-button {
						transform:scale(4);
						background-color:white;
					}
				}
				.slick-button {
					transform:scale(2);
					background-color: var(--brand-color);
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}