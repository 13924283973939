.work-grid {
    .container-fluid {
        overflow-y: hidden;
    }
    
	.grid-container {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-auto-rows: 1fr;

        & > .work-grid--item {
            grid-column: span 1 / span 1;
            
            &:nth-child(3n+1) {
                grid-column: 1 / -1;
                width: 100%;
                aspect-ratio: 362 / 400;
                max-height: 800px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .grid-container {
            grid-template-columns: repeat(2, minmax(0, 1fr));
    
            & > .work-grid--item {

                &:nth-child(3n+1) {
                    aspect-ratio: 362 / 203;
                }
            }
        }
    }
}