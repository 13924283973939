body .gform_wrapper {

	label.gfield_label,
	legend.gfield_label {
		text-transform: none;
		line-height: 1.3em;
		letter-spacing:normal;
		@extend h4;
		font-weight:bold;
	}

	.ginput_container_radio .gchoice {
		position: relative;
	}

	textarea {
		padding:12px !important;
		border-radius:0;
		font-weight:bold;
		@extend h4;
		border: 2px solid var(--gf-border-color);
        outline: 2px solid transparent;
		background-color: white;
		transition:border-color 0.3s ease;
		color: black !important;
		&:focus {
			outline-color: black;
		}
	}
	select,
	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
		font-weight:bold;
		border:none;
		padding:0 12px!important;
		height:52px;
		font-size: calc( 24px + (25 - 24) * (100vw - 400px) / (1440 - 400) )!important;
		line-height: 31px;
		border-radius:0;
		border: 1px solid var(--color);
        outline: 2px solid transparent;
		background-color: white;
		transition: border-color 0.3s ease;
		color: black !important;

		&:focus {
			outline-color: black;
		}
	}

    input[type="button"],
    button[type="submit"] {
        display: inline-block;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        color: #000000;
        border: 1px solid var(--brand-color);
        background: var(--brand-color);
        font-weight: bold;
        padding: 1em 1.4em;
        font-size: 19px;
        line-height: 1em;
        transition: background-color 0.3s ease;

        &:hover {
            color: var(--color);
            background-color: transparent;
        }
    }



	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
