.mobile-nav {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    backdrop-filter:blur(50px);
    transition:background-color 0.3s ease;
    padding-top:calc(140px + 3vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y:auto;
    height:100%;
    visibility: hidden;
    opacity: 0;
    transition:opacity .3s ease 0s,visibility 0s ease .3s;
    .menu-open & {
    	opacity: 1;
	    visibility: visible;
	    transition-delay: 0s,0s;
	    .mobile-nav--menu ul.nav li {
			transform:translateY(0px);
			opacity: 1;
	    }
	    
    }
    @media screen and (min-width:1025px) {
    	display: none!important;
    }

    body.txt--intial-white & {
    	background-color:rgba(0,0,0,0.7);
    }
    body.txt--intial-black & {
    	background-color: rgba(255,255,255,0.9);
    }
    body[data-entrancetext="white"] & {
    	background-color:rgba(0,0,0,0.7)!important;
    }
    body[data-entrancetext="black"] & {
    	background-color: rgba(255,255,255,0.9)!important;
    }

    .mobile-nav--menu {
    	padding:0 12%;
    	ul.nav {
    		margin:0;
    		padding:0;
    		list-style-type:none;
    		li {
    			display: block;
    			width:100%;
    			margin-bottom: 16px;
    			transform:translateY(-15px);
    			opacity: 0;
    			transition: transform 0.3s ease, opacity 0.3s ease;
    			&:nth-child(2) {transition-delay:0.05s};
    			&:nth-child(3) {transition-delay:0.1s};
    			&:nth-child(4) {transition-delay:0.15s};
    			&:nth-child(5) {transition-delay:0.2s};
    			&:nth-child(6) {transition-delay:0.25s};
    			&:nth-child(7) {transition-delay:0.3s};
    			&:nth-child(8) {transition-delay:0.35s};
                &:nth-child(9) {transition-delay:0.4s};
                &:nth-child(10) {transition-delay:0.45s};
    			a {
    				font-size: 45px;
					line-height: 105%;
					font-weight: bold;
    			}
    			&.active {
    				a {
					  color: var(--brand-color);
    				}
    			}
    		}
    	}
    }

}