body[data-entrancetext="black"] {
    .cursor.input {
        background-color: black!important;
    }
}
body[data-entrancetext="white"] .block.generic-form  {
    button[type="submit"] {
        color:white;
    }
    .gfield_checkbox,
    .gfield_radio {
        input {
            &:checked ~ .checkmark {
                &:after {
                    border-color: #000;
                }
            }
        }
    }
}


body .block.generic-form  {
    margin-top: 0;
    .gform_wrapper h1, .gform_wrapper h2, .gform_wrapper h3 {
        font-weight: bold;
    }
    .gform_wrapper li.gfield.gf_list_2col ul.gfield_checkbox li label, .gform_wrapper li.gfield.gf_list_2col ul.gfield_radio li label, .gform_wrapper li.gfield.gf_list_3col ul.gfield_checkbox li label, .gform_wrapper li.gfield.gf_list_3col ul.gfield_radio li label, .gform_wrapper li.gfield.gf_list_4col ul.gfield_checkbox li label, .gform_wrapper li.gfield.gf_list_4col ul.gfield_radio li label {
        white-space:normal;
    }

    .ginput_container_date input {
        width:100%!important;
        max-width:527px!important;
    }

    .gform_wrapper .gsection.gfield {
        border-bottom:none!important;
        margin-bottom:50px!important;
        margin-top:75px!important;
    }
    .gform_wrapper .gsection .gfield_label, .gform_wrapper h2.gsection_title, .gform_wrapper h3.gform_title {
        font-size: calc( 40px + (65 - 40) * (100vw - 400px) / (1440 - 400) );
        font-weight: bold;
    }

    .gform_wrapper,
    form {
    	margin-bottom:10px;

        textarea {
            &::-webkit-scrollbar {
               display: none;
            }
        }
        textarea,
        select,
        input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
            border: 1px solid #C6C6C6!important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            // cursor: none!important;
            background-color: transparent;
            color: map-get($theme-colors, 'grey-2') !important;

            &:focus {
                border:none;
                border-bottom: 1px solid var(--brand-color) !important;
            }
        }

        .ginput_container_select {
            position:relative;
            select {
                position:relative;
                z-index: 1;
                appearance:none;
            }
            &:after {
                content: "";
                border-style: solid;
                border-width: 0.25em 0.25em 0 0;
                display: inline-block;
                height: 0.65em;
                width: 0.65em;
                transform: rotate(135deg);
                border-color:map-get($theme-colors, 'purple');
                position: absolute;
                right: 0;
                top: 50%;
            }
        }

        .gfield_description {
            font-size: 13px;
            line-height: 150%;
            margin-top: 0;
        }

        .gfield_label {
           font-size: 23px;
           color:map-get($theme-colors, 'purple')!important;
            font-weight: bold;
            font-size: 16px!important;
            line-height: 20px!important;
            margin-bottom: 10px;
        }

        .gf_consent_field {
            .gfield_consent_label {


            }
        }

        /**
         * radio and check marks
         */
        .gf_radio_field,
        .gf_checkbox_field {
            margin-bottom: 10px!important;
            .gfield_label {
                font-size: 22px;
                font-weight: bold;
            }
            .gfield_radio,
            .gfield_checkbox {
                margin-top: 15px;
                li {
                    margin-bottom: 0!important;
                }
                label {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        /**
         * Validation Styles
         */

        div.validation_error {
            border: none;
            color: white;
            font-size: 17px;
            display: none!important;
        }
        &.gform_validation_error {
            .gfield.gfield_error {
                border:none;
                padding:0;
                margin:0;
                .ginput_container {
                    padding:0!important;
                    margin:0!important;
                    input, select,textarea {
                        //padding-:0!important;
                        margin:0!important;
                    }
                }
                div.validation_message.gfield_description {
                    color: white;
                    margin: 10px 0 0;
                    padding:5px 20px;
                    font-weight: normal;
                    font-size: 17px;
                    letter-spacing: 1px;
                    background: transparent;
                }
            }
        }
        /**
         *  Required
         */

        .gfield_contains_required {
            .ginput_container_email,
            .ginput_container_select,
            .ginput_container_text,
            .ginput_container_phone {
                position: relative;
            }
        }


        .gfield {
            margin-top: 0!important;
            margin-bottom:45px;
            .ginput_container {
                margin-top: 0!important;
            }
        }



    }

    .gform_save_link {
        font-size: 16px!important;
        text-decoration: underline;
    }

    /**
     * Custom File Input
     */

    .ginput_container.ginput_container_fileupload {
        width: 100%;
        border: 1px dashed #757575;
        padding: 50px;
        text-align: center;
    }

    .ginput_container.ginput_container_fileupload input {
        // cursor: none!important;
    }

    .ginput_container_fileupload input::-webkit-file-upload-button {
      visibility: hidden;
    }
    .ginput_container_fileupload input::before {
        content: 'Drop Files here';
        font-weight: bold;
        font-size: 25px;
        line-height: 130%;
    }
    .ginput_container_fileupload input:hover::before {
      border-color: black;
    }
    .ginput_container_fileupload input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    /**
     * Custom Checkbox
     */
    .gfield_checkbox,
    .gfield_radio {
        display: block;
        position: relative;
        padding-left: 0px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-bottom:50px!important;
        li {
            // cursor: none!important;
        }
        label {
            margin-left:40px;
            text-transform:none;
            font-size: 25px!important;
            line-height: 130%;
            // cursor: none!important;
            margin-bottom: 25px;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
                background-color: #e0108d;
                border-color:#e0108d;
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 9px;
                    width: 6px;
                    height: 14px;
                    border: solid #ffffff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);

                }
            }
        }
        .checkmark {
            position: absolute;
            top: 5px;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border:1px solid #c6c6c6;
            transition:background-color 0.3s ease;
            border-radius:4px;
        }
        &:hover input ~ .checkmark {
            //background-color: #e0108d;
        }


        .label {
            font-size:14px;
            font-weight:500;
        }
    }
}


body .gf_form_alt .block.generic-form {
  .gform_wrapper.gravity-theme .ginput_product_price_wrapper {
    max-width: 100%;
  }


  .gform_wrapper,
  form {


   .gsection.gfield{
      margin-top: 24px !important;
      margin-bottom: 24px !important;
   }

   .gfield{
    margin-bottom: 16px !important;


      select,
      input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
        font-size: calc(19.615px + 0.09615vw) !important;
        padding-left: 12px !important;
        padding-right: 12px !important;


        &:is(.ginput_product_price) {
          border: none !important;
        }
      }
   }

    .gfield_label {
      color: var(--color, black) !important;
    }
    .gform-field-label.gform-field-label--type-sub {
      font-size: 12px !important;
    }

    .ginput_product_price_label{
      display: none !important;

    }

    div.validation_error {
      color: var(--color, black);
      font-size: 12px;
    }
    &.gform_validation_error {
        .gfield.gfield_error {
            div.validation_message.gfield_description {
                color: var(--color, black);
                font-size: 12px;
            }
        }
    }

  }
}
