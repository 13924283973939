.work-browser {
	.col-12 {
		text-align:center;
	}
	.scrollmagic-pin-spacer {
		min-width:0!important;
		left:0!important;
	}

	.work-browser--browser {
		max-height:80vh;
		display:inline-block;
		overflow:hidden;
		border-bottom:10px solid white;
		position: relative;
		width: 100%;
		max-width:calc(100% - 14%);
		margin:0 auto;
		&.pinned {
			left: 50%!important;
			transform: translateX(-50%);
			max-width: 100% - 14.75%;
		}
	}

	.work-browser--bar {
		padding:11px 27px;
		background-color: white;
		border-radius: 15px 15px 0px 0px;
	    display: flex;
	    justify-content: space-between;
	    position: absolute;
	    top: 0;
	    left: 0;
	    z-index: 1;
	    width: 100%;
	}
	.work-browser--dots {
		display: flex;
		align-items:center;
		span {
			width: 11px;
			height: 11px;
			background-color:map-get($theme-colors, 'grey-1');
			border-radius:50%;
			margin:5px 2px;
		}
	}
	.work-browser--link {
		font-size: 16px;
		line-height: 18px;
		font-weight:bold;

		a {
			span {
				text-decoration: underline;
				text-decoration-color: transparent;
				transition: text-decoration-color 200ms ease-in;
	
				html body & {
					color:black!important;
				}
			}

			&:hover span {
				text-decoration-color: currentColor;
			}
		}
	}

	.work-browser--window {
		padding-top: 47px;
		background-color:white;
		border-radius: 15px 15px 0px 0px;
		border-left:10px solid white;
		border-right:10px solid white;
		img {
			width:100%;
			height:auto;
			margin:0 auto;
			display: block;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}