footer.content-info {

	padding:60px 0;

	.footer--separator {
		font-size:25px;
		line-height:32px;
		opacity:0.2;
		margin: 6px;
    	font-weight: bold;
	}

	a {
		position:relative;
		&:not(.social-link) {
    		&:before {
    			content:'';
    			position:absolute;
    			width:0;
    			left:50%;
    			transform:translateX(-50%);
    			height: 2px;
    			background-color: black;
    			bottom:-6px;
    			transition: width 0.3s ease;
    		}
    		&:hover {
    			&:before {
    				width:100%;
    			}
    		}
    	}
	}

	nav.footer-navigation {
		ul {
			margin-top: 20px;
			li {
				& + li {
					&:before {
						content:'/';
						font-size:25px;
						line-height:32px;
						opacity:0.2;
						margin: 0 15px;
				    	font-weight: bold;
					}
				}
				a {
					font-size:25px;
					line-height:32px;
			    	font-weight: bold;
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		nav.footer-navigation {
			ul {
				display: flex;
	    		justify-content: flex-end;
	    		margin-top: 0;
			}
		}
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}
