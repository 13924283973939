/**
 * Theme Colors
 */
$theme-colors: (
	"primary": #000,
	"orange": rgba(209, 224, 0, 1),
	"orange-dark": rgba(209, 224, 0, 1),
	"purple": rgba(209, 224, 0, 1),
	"purple-dark": rgba(209, 224, 0, 1),
	"pink": rgba(209, 224, 0, 1),
	"pink-dark": rgba(209, 224, 0, 1),
	"yellow": rgba(209, 224, 0, 1),
	"yellow-dark": rgba(209, 224, 0, 1),
	"success": #21B665,
	"success-dark": #158146,
	"warning": #CBB901,
	"warning-dark": #AC9D00,
	"error": #CF0C0C,
	"error-dark": #960808,
	"grey-1": #C6C6C6,
	"grey-2": #757575,
    "citrus": rgba(209, 224, 0, 1)
);

$grid-gutter-width: 25px;

/**
 * Shadows
 */
$box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);

/**
 * Gradients
 */
$gradient-1: var(--brand-color);
$gradient-2: var(--brand-color);
$gradient-3: var(--brand-color);
$gradient-4: var(--brand-color);
$gradient-5: var(--brand-color);
$gradient-6: var(--brand-color);
$gradient-7: var(--brand-color);

/**
 * Fonts
 */
$font: 'Helvetica Nueue', 'Arial';

/**
 * Gutenberg columns breakpoints (same as bootstrap defaults)
 */
$break-huge: 2000px;
$break-wide: 1800px;
$break-xlarge: 1200px;
$break-large: 992px;
$break-medium: 768px;
$break-small: 576px;
$break-mobile: 0px;
$break-zoomed-in: 2000px;
