.global--page-cta {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 30px;

	.global--page-cta_link,
	.container-fluid,
	.row {
		height:100%;
	}

    & > .container-fluid > .row {
        row-gap: 3rem;
    }

	.row {
		@media screen and (min-width:1025px) {
			position: relative;

			& > div {
				position: static;
			}
		}
	}

	h1 {
		padding-top:20vh;
	}

	.global--page-cta--text {
		max-width: fit-content;

		h2, p, strong {
			margin-bottom: 0;
            font-size: max(40px, 8.888889vw);
            line-height: 0.8;
		}
	}

	.global--page-cta_mobile-btn_wrap {
		display: block;
	}

	.global--page-cta_mobile-btn {
		display: inline-block;
        position: relative;
		padding: 1em 1.4em .6em;
        border: 2px solid var(--brand-color);
        color: #000000;
        background-color: var(--brand-color);
        transition: color, background-color 300ms ease-in-out;

        &>* {
            color: #000000;
        }

        &:hover {
            color: var(--color);
            background-color: transparent;
        }
	}

    &:after {
        content: '';
        position: fixed;
        inset: 0;
        background-color: #FFFFFF;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms ease-in;
    }

    &.in-view {
        .container-fluid {
            --color: #000000;

            position: relative;
            z-index: 10;
        }

        &:after {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear, opacity 300ms ease-in;
        }
    }

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
    min-height: 60vh;
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}

body.cta-active {
    footer,
    header.header {
        --color: #000000;
        --bg: #FFFFFF;

        color: var(--color);

        a {
            color: var(--color);
        }

        path {
            fill: var(--color);
        }

        .header--menu-title {
            color: var(--color);
        }

        .header--mobile-trigger {
            background-color: var(--color);

            .header--menu-dot {
                background-color: var(--bg);
            }
        }
    }

    @media (hover: hover) {
        header.header:hover {
            --color: #FFFFFF;
            --bg: #000000;
        }
    }

    &.menu-open {
        footer,
        header.header {
            --color: #FFFFFF;
            --bg: #000000;

            .header--mobile-trigger {
                background-color: transparent;
            }
        }

        @media (hover: hover) {
            header.header:hover {
                --color: #000000;
                --bg: #FFFFFF;
            }
        }
    }
}
