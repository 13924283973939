.wp-block-image {
	&.is-style-contained {
		width:100%;
		padding:0 7%;
		margin:7% 0;
		img {
			width:100%;
			height:auto;
		}
	}
	&.is-style-full-width {
		width:100%;
		margin:7% 0;
		img {
			width:100%;
			height:auto;
		}	
	}
	&.is-style-skew {
		img {
			clip-path: polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%);
		}
	}
	figure {
		&.aligncenter {
			text-align:center;
		}
	}
}