.block.insights {

	.insights--title {
		margin-bottom: 8vw;
	}

	.insights--posts_wrap {
		display: flex;
		flex-wrap:wrap;
		margin-left:-4vw;
		margin-right:-4vw;
		min-height:500px;
	}

	ul.insights--categories {
		padding:0;
		margin:0 0 4vw 0;
		list-style-type:none;
		display:flex;
		flex-wrap:wrap;
		li {
            list-style: none;
			margin-bottom: 5px;
			margin-right:22px;
            
			a {
				transition:color 0.3s ease;
				&.active {
					color: var(--brand-color);
				}
			}
		}
	}

	.post-item {
		padding: 4vw;
		width:100%;

		@media screen and (min-width:1025px) {
			max-width:50%;
			flex:0 0 50%;
            
			&:nth-child(10n+6) {
				max-width:100%;
				flex:0 0 100%;
				.post--item_inner {
					display:flex;
					flex-direction: row-reverse;
					.post--item_thumb {
						flex:0 0 33.33%;
						max-width:33%;
						position:relative;
						.post--item_image {
							display:block;
							width:100%;
							height:100%;
							clip-path: inset(0 0 0 95%);
							background: {
								size:cover;
								position:center;
								repeat:no-repeat;
							}
						}
					}
					.post--item_info {
						padding-top: 60px;
						padding-bottom: 40px;
						padding-right:40px;
						display: flex;
						align-items:center;
						flex-wrap:wrap;
						flex: 0 0 66.66%;
						max-width:66%;
					}
				}
			}

			&:nth-child(10n+1) {

				max-width:100%;
				flex:0 0 100%;
				.post--item_inner {
					display:flex;
					.post--item_thumb {
						flex:0 0 33.33%;
						max-width:33%;
						position:relative;
						.post--item_image {
							display:block;
							width:100%;
							height:100%;
							clip-path:inset(0 95% 0 0);
							position:relative;
							background: {
								size:cover;
								position:center;
								repeat:no-repeat;
							}
						}
						
					}
					.post--item_info {
						padding-top: 60px;
						padding-bottom: 40px;
						padding-left:40px;
						flex: 0 0 66.66%;
						max-width:66%;
						display: flex;
						align-items:center;
						flex-wrap:wrap;
					}

					&:hover {
						.post--item_image {
							clip-path: inset(0 0 0% 0)!important;
						}	
					}
				}
			}
		}
	}

	.post--item_inner {
		position:relative;
	}
	.post--item_thumb {
		.post--item_image {
			clip-path: inset(0 0 90% 0);
			transition: clip-path 0.6s ease;
			width:100%;
			height:100%;
			position:absolute;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
			z-index: 1;
		}
	}
	.post--item_info {
		padding-top:80px;
	}
	ul.post--item_meta {
		padding:0;
		margin:0;
		list-style-type:none;
		display: flex;
		align-items:center;
		padding:0;
		a,p,li {
			color:map-get($theme-colors, 'grey-2');
			font-weight: bold;
		}
		li {
			@media screen and (max-width:1024px) {
				font-size:15px;
			}
			position:relative;
			& + li {
				position:relative;
				padding-left:30px;
				&:before {
					content:'/';
					color:rgba(255,255,255,0.2);
					position:absolute;
					left: 10px;
    				top: 2px;
    				font-weight:bold;
				}
			}
		}
	}

	.load-more--insights {
		&:hover {
			.load-more--insights_icon {
				color:white;
			}		
		}
	}
	.load-more--insights_icon {
		font-size: 29px;
		vertical-align: 0px;
		margin-left: 5px;
	}

	.insights--subscribe-wrap {
		text-align:right;
		@media screen and (max-width:1024px) {
			.purple-gradient {
				border:0;
				padding:0;
				font-size:16px;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}