.block.food-conference {

	.food-conference--meta {
		display:flex;
		margin-bottom: 10px;
	}
	.food-conference--date {
		margin-top: 0;
		color:var(--brand-color);
	}
	.food-conference--location {
		margin-left: 15px;
		margin-top: 0;
		color:map-get($theme-colors, 'grey-2');
	}

	.food-conference--link {
		margin-top: 25px;
		a {
			font-weight: bold;
			svg {
				vertical-align: -1px;
			}
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		max-width:750px;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
		
	}
}