.block.content-image-grid {

  .container-fluid {
    display: grid;
    gap: 32px;
  }

  .column {
    flex: 1 1 0;
  }

  .column-inner{
    max-width: 460px;
  }

  .image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    text-align: center;

    .image-container {
      aspect-ratio: 3/4;
      object-fit: cover;
      margin-bottom: 15px;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        margin: 0;
      }
    }
  }

  .link {
    transition: all .5s ease;

    &:hover, &:focus{
      opacity: 0.5;
    }
  }

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

    .container-fluid {
      display: flex;
      justify-content: space-between;
      gap: 32px;
    }


    .image-grid {
      grid-gap: 40px;
    }
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
    .image-grid {
      grid-gap: 120px;
    }
	}

}
