.block.work-video {

	.work-video--grid-container {
		margin-left:-3.5vw;
		margin-right:-3.5vw;
		display: flex;
        flex-direction: column;
        row-gap: 2rem;

		.work-video--video_wrap {
			padding-bottom: 56.25%;
			position:relative;
			background-color: black;
		}

        .work-video--video {
            &.aspect-square {
                .work-video--video_wrap {
                    padding-bottom: 100%;
                }
            }

            &.aspect-portrait {
                .work-video--video_wrap {
                    padding-bottom: 125%;
                }
            }
        }

		.work-video--video_thumb,
		iframe {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
		.work-video--video_thumb {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background: {
				size:cover;
				position:center;
				repeat:no-repeat;
			}
			display: block;
		}
		.work-video--video {
			padding:0 3.5vw;	
		}
	}

    @media screen and (min-width: 768px) {
        .work-video--grid-container {
            flex-direction: row;
            flex-wrap: nowrap;

            &.video_type-1 {
                .work-video--video {
                    flex:0 0 100%;
                    max-width:100%;
                }
            }
            &.video_type-2 {
                .work-video--video {
                    flex:0 0 50%;
                    max-width:50%;
                }
            }
            &.video_type-3 {
                .work-video--video {
                    flex:0 0 33.33%;
                    max-width:33.33%;
                }
            }
        }
    }

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}