button {
    &:focus,
    &:focus-within {
        outline: none !important;
    }
}

.btn {
	display: inline-block;
	transform-style: preserve-3d;
	backface-visibility: hidden;
	border: 1px solid var(--brand-color);
	min-width: 125px;
	padding: 11.5px 24.7px;
	font-weight: bold;
	font-size: 16px;
	line-height: 1em;
    border-radius: 0;
    transition-property: color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    background-color: var(--brand-color);
    color: #000000;

    &:hover {
        color: var(--color);
        background-color: transparent;
    }

    &.ghost {
        color: var(--color);
        background-color: transparent;

        &:hover {
            color: #000000;
            background-color: var(--brand-color);
        }

        .invert & {
            border-color: var(--color);

            &:hover {
                color: var(--bg);
                background-color: var(--color);
            }
        }
    }
}

.is-style-magnetic-btn {
	.wp-block-button__link {
		display: inline-block;
		border: 2px solid var(--brand-color);
		font-weight: bold;
		padding: 1em 1.4em;
		font-size: 19px;
		line-height: 1em;
        transition-property: color, background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        background-color: var(--brand-color);
        border-radius: 0;
        cursor: pointer;
        color: #000000;

		&:hover {
            color: var(--color);
            background-color: transparent;
		}
	}
}

.wp-block-button {
	&__link {
		display: inline-block;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        border: 1px solid var(--brand-color);
        min-width: 125px;
        padding: 11.5px 24.7px;
        font-weight: bold;
        font-size: 16px;
        line-height: 1em;
        border-radius: 0;
        transition-property: color, background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        background-color: var(--brand-color);
        color: #000000;

        &:hover {
            color: var(--color);
            background-color: transparent;
        }
	}

	&.is-style-outline {
		.wp-block-button__link {
            color: var(--color);
            background-color: transparent;
    
            &:hover {
                color: #000000;
                background-color: var(--brand-color);
            }
    
            .invert & {
                border-color: var(--color);
    
                &:hover {
                    color: var(--bg);
                    background-color: var(--color);
                }
            }
        }
	}

	&.is-style-solid {
		.wp-block-button__link {
			@include button-variant(
				map-get($theme-colors, 'primary'),
				map-get($theme-colors, 'light')
			);
		}
	}
}

.new-btn {
    all: unset;
    display: inline-block;
    position: relative;
    padding: 0.8em 1.4em;
    border: 2px solid var(--brand-color);
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    vertical-align: middle;
    color: #000000;
    background-color: var(--brand-color);
    transition-property: color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

	@include media-breakpoint-up(lg) {
        font-size: calc(24px + 1 * (100vw - 400px) / 1040);
	} 

    &:after {
        content: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 0.416687L9.34064 2.32606L16.8969 9.89585H0.416687V12.6042H16.8969L9.34064 20.174L11.25 22.0834L22.0834 11.25L11.25 0.416687Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
        vertical-align: middle;
    }

    &:hover {
        color: var(--color);
        background-color: transparent;
    }

    &.ghost {
        color: var(--color);
        background-color: transparent;

        &:after {
            content: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 0.416687L9.34064 2.32606L16.8969 9.89585H0.416687V12.6042H16.8969L9.34064 20.174L11.25 22.0834L22.0834 11.25L11.25 0.416687Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
        }

        &:hover {
            color: #000000;
            background-color: var(--brand-color);
        }
    }
}
