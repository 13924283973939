.job-apply {
    height: 60vh;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
        height: 80vh;
    }

    .job-apply__content {
        position: relative;
        z-index: 10;
        color: #000000;
        display: flex;
        flex-direction: column;
        gap: max(2.5rem, 10vw);

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: flex-end;
        }

        & > h1 {
            margin-bottom: 0;
            color: #000;
        }

        .job-apply__button-container > button {
            all: unset;
            display: inline-block;
            position: relative;
            padding: 0.8em 1.4em;
            border: 4px solid #000000;
            border-width: 2px;
            color: #000000;
            background-color: transparent;
            font-size: calc(24px + 1 * (100vw - 400px) / 1040);
            font-weight: 700;
            cursor: pointer;
            vertical-align: middle;
            transition-property: color, background-color;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;

            &:after {
                content: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 0.416687L9.34064 2.32606L16.8969 9.89585H0.416687V12.6042H16.8969L9.34064 20.174L11.25 22.0834L22.0834 11.25L11.25 0.416687Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
                vertical-align: middle;
            }

            &:hover {
                color: #FFFFFF;
                background-color: #000000;

                &:after {
                    content: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 0.416687L9.34064 2.32606L16.8969 9.89585H0.416687V12.6042H16.8969L9.34064 20.174L11.25 22.0834L22.0834 11.25L11.25 0.416687Z' fill='white'%3E%3C/path%3E%3C/svg%3E");
                }
            }
        }
    }

    &:after {
        content: '';
        position: fixed;
        inset: 0;
        background: $gradient-3;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 300ms, opacity 300ms ease-in;
    }

    &.in-view:after {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, opacity 300ms ease-in;
    }
}

.job-application {
    position: fixed;
    inset: 0;
    overflow-y: auto;
    z-index: 200;
    backdrop-filter: blur(3px);
    color: #000;

    .job-application__overlay {
        position: fixed;
        inset: 0;
        background: $gradient-5;
        z-index: 0;
        opacity: 0.9;
    }

    .job-application__content-wrapper {
        position: relative;
        z-index: 10;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            justify-content: center;
        } 
    }

    .job-application__content {
        position: relative;
        padding-block: 8rem;

        .job-application__header {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            gap: 1rem 12%;
            border-bottom: 1px solid #000;
            padding-bottom: 40px;
            color: #000 !important;

            & > h1 {
                color: #000;
                line-height: 1.05;
                flex: 1 0 50%;
                min-width: min(100%, 600px);

                & > small {
                    display: block;
                    font-size: calc( 24px + (25 - 24) * (100vw - 400px) / (1440 - 400) );
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }

            & > div {
                color: #000 !important;
                flex: 1 1 33%;
                min-width: min(100%, 350px);

                & > * {
                    color: #000 !important;
                }
            }
        }

        .job-application_form-container {
            width: 100%;

            .gform_heading {
                display: none;
            }

            .gform_fields {
                display: flex;
                flex-direction: column;
                padding-top: 2.5rem;
                grid-template-columns: repeat(3, 1fr);
                gap: 1rem;

                @include media-breakpoint-up(md) {
                    display: grid;
                    align-items: center;
                } 

                .gfield {
                    &.gfield--width-half {
                        grid-column: span 1;
                    }

                    &:is(.gsection) {
                        grid-column: 1 / 2;
                        margin: 0;
                        padding: 0;
                        border-bottom: 0;

                        h3 {
                            margin: 0;
                            padding: 0;
                            color: #000;
                            font-size: calc( 24px + (25 - 24) * (100vw - 400px) / (1440 - 400) );
                        }
                    }

                    &:is(fieldset), 
                    &:not(.gfield--width-half, .gsection) {
                        display: flex;
                        flex-direction: column;
                        grid-template-columns: repeat(3, 1fr);
                        gap: 1rem;
                        grid-column: 1 / -1;
                        position: relative;


                        @include media-breakpoint-up(md) {
                            display: grid;
                            align-items: center;
                        }

                        .gfield_label {
                            margin: 0;
                            padding: 0;
                            grid-column: 1 / 2;
                            color: #000;
                            font-size: calc( 24px + (25 - 24) * (100vw - 400px) / (1440 - 400) );

                            &:is(legend) {
                                color: #000;
                                padding-bottom: 1rem;

                                @include media-breakpoint-up(md) {
                                    width: calc(100% / 3);
                                    position: absolute;
                                    top: 0;
                                    display: flex;
                                    align-items: center;
                                    height: 100%;
                                    padding-bottom: 0;
                                }
                            } 
                        }

                        .gf_name_has_2 {
                            gap: 1rem;

                            & > * {
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                            }
                        }

                        .ginput_container {
                            grid-column: 2 / -1;
                        }
                    }

                    input, textarea {
                        width: 100%;
                        font-size: calc(24px + 1 * (100vw - 400px) / 1040);
                    }

                    .gform_drop_area {
                        background: $white;
                        border: none;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 2rem;

                        @include media-breakpoint-up(md) {
                            flex-direction: row;
                        } 

                        .gform_drop_instructions {
                            margin-bottom: 0;
                            font-size: calc(24px + 1 * (100vw - 400px) / 1040);
                            font-weight: 700;
                        }

                        .gform_button_select_files {
                            --gradient: #{$gradient-4};

                            padding: 0.5em 1em;
                            
                            &:before {
                                opacity: 0;
                            }

                            &:hover {
                                &:before {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            .gform_footer {
                justify-content: flex-end;
            }

            .btn,
            .button {
                --gradient: #{$gradient-2};
        
                all: unset;
                display: inline-block;
                position: relative;
                padding: 0.8em 1.4em;
                border: 4px solid;
                border-width: 2px;
                border-image-slice: 1;
                color: #000;
                border-image-source: var(--gradient);
                font-size: calc(24px + 1 * (100vw - 400px) / 1040);
                font-weight: 700;
                cursor: pointer;
                vertical-align: middle;

                &:after {
                    content: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.25 0.416687L9.34064 2.32606L16.8969 9.89585H0.416687V12.6042H16.8969L9.34064 20.174L11.25 22.0834L22.0834 11.25L11.25 0.416687Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
                    vertical-align: middle;
                    margin-left: 0.5rem;
                }
        
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    inset: 0;
                    background: var(--gradient);
                    transition: opacity 0.5s ease;
                    z-index: -10;
                }
        
                &:hover {
                    &:before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .job-application__close {
        all: unset;
        position: absolute;
        top: 2rem;
        right: 12%;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;
        font-weight: 700;

        @include media-breakpoint-up(md) {
            top: 5.25rem;
        }

        &:hover {
            & > span:last-child {
                opacity: 1;
            }
        }

        & > span:last-child {
            font-size: 3rem;
            height: 35px;
            line-height: 22px;
            opacity: 0.5;
            font-weight: 400;
            transition: opacity 300ms ease;
        }
    }
}
