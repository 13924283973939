.block.capabilities {
	
	@media screen and (min-width:768px) {
		.capabilities--menu {
			position:sticky;
			right:0;
			top:15vw;
			text-align:right;
			ul {
				text-align:left;
				list-style-type:none;
				padding:0 0 0 20px;
				margin:0;
				li {
					position:relative;
					a {
						transition:color 0.3s ease;
					}
					&.active,
					&:hover {
						&:before {
							opacity: 1;
						}
					}
					&:before {
						transition:opacity 0.3s ease;
						opacity: 0;
						content:'';
						position:absolute;
						background-color:red;
						width: 6px;
						height: 6px;
						top: 9px;
						left:-15px;
						border-radius:50%;
					}
					&.orange {
						&:before {
							background-color:map-get($theme-colors, 'orange');
						}
						&:hover,
						&.active {
							a {
								body:not([data-entrancetext="black"]) &,
								body:not([data-entrancetext="white"]) & {
									color:map-get($theme-colors, 'orange');
								}
							}
						}
					}
					&.purple {
						&:before {
							background-color:map-get($theme-colors, 'purple');
						}
						&:hover,
						&.active {
							a {
								body:not([data-entrancetext="black"]) &,
								body:not([data-entrancetext="white"]) & {
									color:map-get($theme-colors, 'purple');
								}
							}
						}
					}
					&.pink {
						&:before {
							background-color:var(--brand-color);
						}
						&:hover,
						&.active {
							a {
								body:not([data-entrancetext="black"]) &,
								body:not([data-entrancetext="white"]) & {
									color:var(--brand-color);
								}
							}
						}
					}
					&.yellow {
						&:before {
							background-color:map-get($theme-colors, 'yellow');
						}
						&:hover,
						&.active {
							a {
								body:not([data-entrancetext="black"]) &,
								body:not([data-entrancetext="white"]) & {
									color:map-get($theme-colors, 'yellow');
								}
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width:767px) {
		.capabilities--section_features {
			h5 {
				font-size: 20px;
			}
		}
		.capabilities--menu {
			margin-left: calc(-12% - 20px);
			margin-right: calc(-12% - 20px);
			ul {
				margin: 0;
				margin-top: 7vw;
				body:not([data-entrancetext="black"]) & {
					background-color: #191919;
				}
			    padding: 0;
			    list-style: none;
			    display: flex;
			    flex-wrap: nowrap;
			    overflow-x: auto;
			    padding-left: calc(12% - 5px);
			    -ms-overflow-style: none;  /* IE and Edge */
				scrollbar-width: none;  /* Firefox */
				&::-webkit-scrollbar {
				  display: none;
				}

			    li {
			    	padding:16px 20px;
			    	h5 {
			    		font-size: 20px;
			    	}
			    	&:last-child {
			    		padding-right: calc(12% - 5px);
			    	}

			    	&.active {
						&.orange {
							h5 a {
								color:map-get($theme-colors, 'orange');
							}
						}
						&.purple {
							h5 a {
								color:map-get($theme-colors, 'purple');
							}
						}
						&.pink {
							h5 a {
								color:var(--brand-color);
							}
						}
						&.yellow {
							h5 a {
								color:map-get($theme-colors, 'yellow');
							}
						}
					}
			    }
			}
		}
	}

	.capabilities--intro_title {
		margin-bottom:0.3em;
	}
	.capabilities--intro_text {
		font-size:20px;
		line-height:30px;
	}


	.capabilities--section {
		display:none;
		margin-top:14%;
		&.initial {
			display:block;
		}
	}

	.capabilities--section_image {
		position:relative;
		padding-bottom:12%;
		margin-bottom:14%;
		width:calc(100vw - 12.5%);
		img {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			object-fit:cover;
			object-position:center;
		}
	}
	.capabilities--section_title {
		margin-bottom:1em;
		&.txt-orange {
			body:not([data-entrancetext="black"]) &,
			body:not([data-entrancetext="white"]) & {
				color:map-get($theme-colors, 'orange');
			}
		}
		&.txt-purple {
			body:not([data-entrancetext="black"]) &,
			body:not([data-entrancetext="white"]) & {
				color:map-get($theme-colors, 'purple');
			}
		}
		&.txt-pink {
			body:not([data-entrancetext="black"]) &,
			body:not([data-entrancetext="white"]) & {
				color:var(--brand-color);
			}
		}
		&.txt-yellow {
			body:not([data-entrancetext="black"]) &,
			body:not([data-entrancetext="white"]) & {
				color:map-get($theme-colors, 'yellow');
			}
		}
	}

	.capabilities--section_features {
		padding:0;
		list-style-type:none;
		li {
			margin-bottom:1em;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}