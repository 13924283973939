.block.job-details {
    margin-top: max(6.25rem, 12.5%);
    margin-bottom: 0;
    padding-top: max(2.5rem, 5%);
    padding-bottom: max(2.5rem, 5%);
    border-top: 1px solid var(--brand-color);

    & > ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        list-style: none;
        padding: 0;
        margin-top: 1rem;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
        } 

        li {
            list-style: none;
            margin-bottom: 0;

            h5 {
                color: var(--brand-color);
            }
        }
    }
}