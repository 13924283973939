.easy-voice-mail-controls > button {
    padding: 12px 16px;
    line-height: 1;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;

    &#easy_voice_mail_record {
        background: #63f7a8;
        line-height: 0;
        text-indent: -9999px;

        &::after {
            content: "Start Recording";
            text-indent: 0;
            display: block;
            line-height: initial;
        }
    }

    &#easy_voice_mail_stop {
        background: #63f7a8;
        line-height: 0;
        text-indent: -9999px;

        &::after {
            content: "Finish Recording";
            text-indent: 0;
            display: block;
            line-height: initial;
        }
    }

    &#easy_voice_mail_play {
        background: #fcb93f;
    }

    &#easy_voice_mail_pause {
        background: #fcb93f;
    }

    &#easy_voice_mail_save {
        background: #63f7a8;
        line-height: 0;
        text-indent: -9999px;

        &::after {
            content: "Submit";
            text-indent: 0;
            display: block;
            line-height: initial;
        }

        &[style*="block"] ~ #easy_voice_mail_cancel {
            &::after {
                content: 'Delete Recording'
            }
        }
    }

    &#easy_voice_mail_cancel {
        background: #f74f3e;

        line-height: 0;
        text-indent: -9999px;

        &::after {
            content: "Restart";
            text-indent: 0;
            display: block;
            line-height: initial;
        }
    }

    &:hover {
        opacity: 0.8;
    }
}