.block.staff {

	.staff--categories {
		margin:50px 0 70px;
		list-style-type:none;
		display: flex;
		align-items:center;
		padding:0;
		li {
			& + li {
				position:relative;
				padding-left:40px;
				&:before {
					content:'/';
					color:rgba(255,255,255,0.2);
					position:absolute;
					left: 17px;
    				top: 6px;
				}
			}
			a {
				color:map-get($theme-colors, 'grey-2');
				transition:color 0.3s ease;
				&:hover,
				&.active {
					color:white;
				}
			}
		}
		
		h4 {
			font-weight:bold;
		}
	}

	.staff--item_img {
		max-width:100%;
		margin-bottom:30px;
		img {
			width:100%;
			filter: grayscale(100%);
			transition:filter 0.3s ease;
			&:hover {
				filter:none;
			}
		}
	}

	.staff--item {
		margin:0 auto 14%;
		h6 {
			color:map-get($theme-colors, 'grey-2');
			font-weight: bold;
		}
		&:hover {
			.staff--item_bio--trigger {
				opacity:1;
				transform:translateX(0px);
			}
		}

	}
	.staff--item_meta {
		position:relative;
	}
	.staff--item_bio--trigger {
		position:absolute;
		color: var(--brand-color);
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		bottom:0;
		right:0;
		opacity:0;
		transform:translateX(20px);
		transition: all 0.3s ease;
	}
	.staff--item_bio {
		padding-top: 15px;
		display: none;
		font-size: 16px;
		line-height: 24px	;
	}

	.load-more-btn--wrap {
		margin-top: 7vw;
		text-align:center;
	}
	.load-more {
		border-radius:0;
		display: inline-block;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transition: transform 0.2s ease;
		border: 1px solid var(--brand-color);
		padding: 11.5px 24.7px;
		font-weight: bold;
		font-size: 16px;
		line-height: 1em;
		transition: color 0.3s ease;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}