body:is(.single-post, .single-career) {
	
	ul.single--categories {
		list-style-type:none;
		padding:0;
		margin:0 0 30px 0;

		li {
			display:block;
			margin-bottom: 0;
		}

		li,
		h5 {
			color: var(--color);
		}
	}

	.single--header {
		margin-top:40px;
	}

	.author--meta {
		margin-top:1%;
		display:flex;
		.author--meta_image {
			max-width:39px;
			padding-right:10px;
			img {
				border-radius:50%;
			}
		}
		.author--meta_info {

			h5 {
				margin-bottom: 0;
			}
			h6 {
				color:map-get($theme-colors, 'grey-2');
			}
		}
	}

	.single--intro_text {
		margin-top:50px;
		margin-bottom:75px;
	}

	.entry-content {
		margin-top:100px;
	}

	.jump-to-menu--title {
	 	border-top:1px solid #C6C6C6;
	 	padding:35px 0 0 0;
	 	margin:35px 0 0 0;
	 	@media screen and (min-width:1025px) {
	 		padding:25px 0 0 0;
	 		margin-top: 25px;
	 	}
		font-weight: bold;
		font-size: 20px;
		line-height: 130%;
	 }

	 .single-work--posts_nav {
	 	.wp-block-separator {
	 		max-width:100%;
	 	}
	 	.container-fluid {
	 		padding:0;
	 	}
	 }


	 @media screen and (max-width:1025px) {
	 	.jump-to-menu {
	 		margin-top: 17px!important;
	 		background-color: #f9f9f9;
	 		display:flex;
	 		flex-wrap:nowrap;
 		    overflow-x: auto;
			overflow-y: hidden;
	 		padding:15px 20px!important;
	 		-ms-overflow-style: none;  /* IE and Edge */
  			scrollbar-width: none;  /* Firefox */
	 		&::-webkit-scrollbar {
			  display: none;
			}
	 		li {
	 			margin-right:10px;

	 			a {
                    color: #000000;
	 				padding:10px;
	 				transition:all 0.3s ease;
	 				white-space: nowrap;
	 			}

	 			&.active {
	 				a {
	 					background-color:white;
	 					color:var(--brand-color);
	 				}
	 			}
	 		}
	 	}
	 }

	.jump-to-menu {
		list-style-type:none;
		margin:0 0 50px 0;
		padding:10px 0 0 0;

		li {
			margin-bottom:3px;
			a {
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				transition:color 0.3s ease;
				position:relative;
				&:before {
				    content: "";
				    height: 6px;
				    width: 6px;
				    border-radius: 50%;
				    left: -15px;
				    top: 7px;
				    opacity: 0;
				    transition: opacity 0.3s ease;
				    display: block;
				    background-color:var(--brand-color);
				    position: absolute;
				}
			}
			&.active {
				a {
					color:var(--brand-color);
					&:before {
						opacity:1;
					}
				}
			}
		}
	}

	.single--featured-image {
		position: relative;
		padding-bottom: 40%;
		margin-top:7%;
		@media screen and (min-width:1025px) {
			margin-top:0;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	.reset-bg {
		padding-top:7%;
		display:block;
		width:100%;
	}
	.single--related-posts {
		.block.related {
			padding:0 30px 7% 30px;
		}
		.container-fluid {
			padding:0;
		}
	}

	.entry-content {
		display:flex;
		flex-wrap:wrap;
	}
	.single--content {
		max-width:100%;
		flex:0 0 100%;

        a {
            color: var(--color);
            text-decoration: underline;
            background-image: none !important;

            &:hover {
                background-image: none !important;
                text-decoration-color: var(--brand-color) !important;
            }
        }
		
	}
	.single--sidebar {
		max-width:100%;
		flex:0 0 100%;
	}

	@media screen and (max-width:1024px) {
		.single--header_r {
			display:flex;
			justify-content: space-between;
			.author--meta_image {
				display:none;
			}
		}
	}

	@media screen and (min-width:1025px) {

		.single--header {
			display:flex;
			flex-wrap:nowrap;
			margin-left:-30px;
			margin-right:-30px;
		}
		.single--header_l {
			max-width:75%;
			flex:0 0 75%;
			padding-left:30px;
			padding-right: 30px;
		}
		.single--header_r {
			max-width:25%;
			flex:0 0 25%;
			padding-left:30px;
			padding-right: 30px;
		}

		.entry-content {
			display:flex;
			flex-wrap:wrap;
			margin-left:-30px;
			margin-right:-30px;
			flex-direction:row-reverse;
		}
		.single--content {
			flex:0 0 75%;
			max-width:75%;
			padding-left: 30px;
			padding-right: 55px;
		}
        
		.single--sidebar {
			flex: 0 0 25%;
			max-width: 25%;
			padding-left: 55px;
			padding-right: 30px;

			.single--sidebar_content.is-sticky {
				position: sticky;
				top: 10vw;
				bottom: 10vw;
			}

            .single--sidebar_cta {
                .single--sidebar_cta-img {
                    width: 100%;
                    aspect-ratio: 25 / 18;
                }

                .single--sidebar_cta-title {
                    margin-top: 1.5rem;
                    text-wrap: balance;
                }

                a.single--sidebar_cta-link {
                    display: inline-block;
                    margin-top: 1.5rem;
                    border-color: var(--color);
                    padding: 0.75em 1.5em;

                    &:hover {
                        color: var(--bg);
                        background-color: var(--color);
                    }

                    &:after {
                        display: none;
                    }
                }
            }
		}

	}
}

body:is(.single-post, .single-career) {
    .single--sidebar {
		.single--sidebar_share {	
			position:relative;		
			font-weight: bold;
			font-size: 20px;
			line-height: 130%;
			margin-bottom: 35px;
			@media screen and (min-width:1025px) {
				margin-bottom: 25px;
			}
			& > a {
				transition: opacity 0.3s ease;
			}
			svg {
				vertical-align: -2px;
			}
			&.open {
				& > a {
					opacity:0;
				}
				ul.share-post {
					visibility:visible;
					opacity:1;
					a {
						opacity: 1;
						transform:translateX(0);
						
						&:hover {
							transition-delay: 0 !important;
							opacity: 0.8;
						}
						&.facebook {
							transition-delay:0.3s;
						}
						&.twitter {
							transition-delay:0.4s;
						}
						&.linkedin {
							transition-delay:0.5s;
						}
					}
				}
			}
		}
	}

	ul.share-post {
		display: flex;
		position:absolute;
		top:0;
		left:0;
		padding: 0;
		list-style-type: none;
		align-items:center;
		visibility:hidden;
		opacity:0;
		transition:visibility 0s ease 0s, opacity 0.3s ease 0.3s;
		a {
			display: block;
			opacity: 0;
			transition:transform 0.3s ease, opacity 0.3s ease;;
			transform: translateX(-10px);
		}
		li {
			margin-bottom: 0;
			& + li {
				margin-left:17px;
			}
		}
		svg path {
			fill: var(--color);
		}

		.init-share {

			span {
				text-decoration: underline;
				text-decoration-color: transparent;
				transition: text-decoration-color 200ms ease-in;
	
				&:hover {
					text-decoration-color: currentColor;
				}
			}

			svg path {
				fill: var(--color);
			}
		}
	}
	.init-share {
		span {
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: text-decoration-color 200ms ease-in;

			&:hover {
				text-decoration-color: currentColor;
			}
		}
	}
}