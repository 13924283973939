html body:not(.wp-admin) {
    &[data-entrancebg="black"] {
        .cursor {
            background-color:white;
        }
    }
    &[data-entrancebg="white"] {
        .cursor {
            background-color:black;
        }
    }
     &[data-entrancetext="black"] {
        .cursor {
            background-color:black;
        }
    }
    &[data-entrancetext="white"] {
        .cursor {
            background-color:white;
        }
    }
    &[data-entrancebg="gradient_1"],
    &[data-entrancebg="gradient_2"],
    &[data-entrancebg="gradient_3"],
    &[data-entrancebg="gradient_4"],
    &[data-entrancebg="gradient_5"] {
        .cursor {
            mix-blend-mode:overlay;
        }
    }
    .cursor {
        @media screen and (max-width:1024px) {
            display: none!important;
        }
        opacity:0;
        position: fixed;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        top: var(--y, 0);
        left: var(--x, 0);
        transform: translate(-50%, -50%) scale(0.125);
        z-index: 2;
        pointer-events: none;
        transition:transform 0.3s ease, background-color 0.3s ease, opacity 0.3s ease;
        z-index: 1001;
        mix-blend-mode:exclusion;
        &.white {
            background-color: white!important;
        }
        &.black {
            background-color: white;
            mix-blend-mode: exclusion;
        }
        &.trans-25 {
            opacity: 0.25;
        }
        &.hidden {
            opacity: 0;
        }
        &.grow {
            transform: translate(-50%, -50%) scale(0.25);
            opacity:0.75;
            mix-blend-mode:overlay;
        }
        &.link {
            &.grow {
                mix-blend-mode: overlay;
            }
            transform: translate(-50%, -50%) scale(0.25);
            //opacity:0.75;
            mix-blend-mode: difference;
            &.play-icon {
                mix-blend-mode: normal;
                background-color: rgba(255,255,255,1)!important;
                &:before {
                    content:"";
                    border-style: solid;
                    border-width: 32px 0px 32px 48px;
                    border-color: transparent transparent transparent black;
                    position: absolute;
                    left: 53%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }

        &.normal-blend-mode {
            mix-blend-mode:normal;
        }

        .cursor--text {
            color:black;
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
            white-space: nowrap;
            line-height:1.3;
            font-weight: bold;
            font-size: 22px;
        }
        &.big-gradient {
            background: $gradient-4;
            transform: translate(-50%, -50%) scale(0.75);
            mix-blend-mode:normal;
            text-align:center;
        }
        &.big-gradient-2 {
            background: $gradient-4;
            transform: translate(-50%, -50%) scale(0.875);
            mix-blend-mode:normal;
            text-align:center;
            .cursor--text {
                transform:translate(-50%, -50%);
            }
        }
        &.gradient_1 {
            background: $gradient-1;
            transform: translate(-50%, -50%) scale(1);
            mix-blend-mode:normal;
        }
        &.gradient_2 {
            background: $gradient-2;
            transform: translate(-50%, -50%) scale(1);
            mix-blend-mode:normal;
        }
        &.gradient_3 {
            background: $gradient-3;
            transform: translate(-50%, -50%) scale(1);
            mix-blend-mode:normal;
        }
        &.gradient_4 {
            background: $gradient-4;
            transform: translate(-50%, -50%) scale(1);
            mix-blend-mode:normal;
        }
        &.gradient_5 {
            background: $gradient-5;
            transform: translate(-50%, -50%) scale(1);
            mix-blend-mode:normal;
        }
        &.difference {
            mix-blend-mode: difference;
        }

        &.overlay-blend-mode {
            mix-blend-mode: overlay;
        }

        &.slick-dot {
            opacity: 0!important;
        }

        &.opacity-75 {
            opacity:0.75!important
        }

        &.slight-grow {
            transform: translate(-50%, -50%) scale(0.25);
        }

        &.video-playing {
            background:rgba(0,0,0,0.65);
            transform: translate(-50%, -50%) scale(0.375);
            svg {
                fill:white;
                width:100%;
                padding:16px;
            }
        }
        &.big-white {
            transform: scale(0.625);
            background-color: white;
            mix-blend-mode:normal;
            svg {
                transform:scale(0.4);
            }
        }

        .cursor--icons-wrap {
            font-size: 12px;
            vertical-align: 2px;
            margin-left: 8px;
        }
        // For the video play, hide the pause SVG on load
        .fa-pause {
            display: none;
        }
    }
}


.flex-video {
  position: relative;
  aspect-ratio: 16 / 9;
  display: block;
  width: 100%;

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
