.block.work-info-strip {

	&.normal {
		.work-info-strip--title {
			max-width:641px;
		}
	}

	&.bigger {
		.work-info-strip--title {
			max-width:840px;
		}
	}
	&.full {
		.work-info-strip--title {
			max-width:100%;
		}
	}
	.work-info-strip--pre_title {
		margin-bottom:30px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}