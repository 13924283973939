.error404 {

	.error404-top-wrap {
		padding:5vw 0 28vw;
		@media screen and (min-width: 768px) {
			padding:5vw 0;
		}
	}
	.block.page-header {
		display: none;
	}
	.error404-title {
		margin-bottom: 25px;
	}
	.error404-button {
		margin-top: 40px;
	}
	.error404-content-wrap {
		text-align:center;
		@media screen and (min-width: 768px) {
			text-align:left;
		}
	}
	.error404-404_wrap {
		text-align:center;
		@media screen and (min-width: 768px) {
			text-align:right;
		}
	}
	.gradient-text-404 {
		font-size:180px;
		text-align:center;
		@media screen and (min-width: 768px) {
			line-height: 1em;
			font-size: 16vw;
		}
		background: linear-gradient(
			270deg,
			#8969d3 0%,
			#d954a5 27.89%,
			#e8509d 33.01%,
			#f59066 59.37%,
			#faaa4f 72.41%,
			#fcd21a 89.8%,
			#fde600 100%
		);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		display: inline-block;
		margin-top: -25px;
	}
}
