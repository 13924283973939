.single-career {

    header.header {
        .header--menu-dot {
            background: #000;
        }

        &:hover {
            .brand svg > path {
                fill: #FFFFFF;
            }
    
            .header--menu-title {
                color: $white;
            }

            .header--menu-dot {
                background: $white;
            }
    
            .primary-navigation ul a {
                color: $white;
            }
        }
    }

    main.main {
        padding-bottom: 0;

        .single--sidebar {
            margin-bottom: 2rem;
    
            @media screen and (min-width:1025px) {
                margin-bottom: 0;
            }
        }
    }

    .entry-content {
        --gradient: #{$gradient-1};

        border-bottom: 1px solid;
        border-image-slice: 1;
        border-image-source: var(--gradient);
    }
	 
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}