.block.featured-video {
	@media screen and (max-width:1024px) {
		margin-top: -120px;
	}
	.featured-video--video_wrap {
		position:relative;
		width:calc(100% - 20%);
		padding-bottom:56.25%;
		margin:0 auto;
		transition:width 0.5s ease;
		video {
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			width:100%;
			height:100%;
		}
	}

	&.in-view {
		.featured-video--video_wrap {
			width:100%;
		}

		.featured-video--button {
			opacity: 1;
		}
	}

	.featured-video--button {
		position: absolute;
		display: flex;
		bottom: 20%;
		right: 50%;
		transform: translateX(50%);
		cursor: pointer !important;
		color: #000;
		transition: all 300ms ease-in;
		padding: 16px 25px;
		min-width: 0;
		font-size: 20px;
		line-height: 1.2;
        opacity: 0;
		border: 2px solid var(--brand-color);
        background-color: var(--brand-color);

		&:focus {
			box-shadow: none;
		}

        &:hover {
            color: #FFFFFF;
            background-color: #000000;
        }

		@media screen and (max-width: 767px) {
			&.playing {
				background: linear-gradient(
					to left,
					map-get($theme-colors, 'yellow'),
					map-get($theme-colors, 'orange')
				);
				border-radius: 9999px;
				width: 40px;
				height: 40px;
				right: 2%;
				bottom: 82%;
				transform: translateX(0);
				padding: 0;

				&:hover:before {
					color: #000;
					opacity: 1 !important;
				}

				span {
					display: none;
				}
	
				&:before {
					background: none;
					content: "X";
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&:after {
					display: none;
				}
			}
		}

		svg {
			position: relative;
			z-index: 100;
		}
	}
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}