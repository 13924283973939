.block.work-dynamic-image {

	.work-dynamic-image--grid-container {
		display: grid;
		gap: 4vw;
	}

	.work-dynamic-image--image {
		img {
			max-width:none;
			width:100%;
		}
	}

	.work-dynamic-image__portrait {
    img {
      height: auto;
      object-fit: cover;
			aspect-ratio: 9/16;
		}
    .flex-video{
			aspect-ratio: 9/13.7;
    }
	}
	.work-dynamic-image__landscape {
		img {
      height: auto;
      object-fit: cover;
			aspect-ratio: 16/9;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {

	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	.work-dynamic-image--grid-container {
		grid-template-columns: repeat(5, 11.6vw);
		gap: 7vw;
	}

	&.wdi_single-1,
	&.wdi_single-2 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 6;
		}
	}

	&.wdi_single-2 {
		.wdi_item-0 {
			margin-left: -7vw;
			margin-right: -7vw;
		}

    .work-dynamic-image--image:has(.flex-video){
      max-height: 624px;
      overflow: hidden;
      position: relative;

      .flex-video {
          position: relative;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
      }
    }
	}


	&.wdi_single-3 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 2 / 3 / 5;
		}
	}

	&.wdi_single-4 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 4;
			margin-right:-6vw;
		}
	}
	&.wdi_single-5 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 3 / 3 / 6;
			margin-left:-6vw;
		}
	}

	&.wdi_single-6 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 6;
			margin-right:-7vw;
		}
	}
	&.wdi_single-7 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 6;
			margin-left:-7vw;
		}
	}
	&.wdi_single-8 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 5;
			margin-left:-7vw;
		}
	}
	&.wdi_single-9 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 2 / 4 / 6;
			margin-right:-7vw;
		}
	}

	&.wdi_double-1 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 3;
			margin-left:-7vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 3 / 4 / 6;
		}
	}

	&.wdi_double-2 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 4;
		}
		.wdi_item-1 {
			grid-area: 1 / 4 / 4 / 6;
			margin-right:-7vw;
		}
	}

	&.wdi_double-3 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 3;
			margin-right:-7vw - 2.3vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 4 / 3 / 6;
			margin-left:-7vw - 2.3vw;
		}
	}

	&.wdi_double-4 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(3, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 4 / 3;
			margin-left:-7vw;
			margin-right:-7vw - 2.3vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 4 / 4 / 6;
			margin-left:-7vw - 2.3vw;
			margin-right:-7vw;
		}
	}

	&.wdi_double-5 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 3;
			margin-right:-7vw - 2.3vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 4 / 3 / 6;
			margin-left:-7vw;
			margin-right:-7vw - 2.3vw;
		}
	}

	&.wdi_double-6 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 3;
			margin-left:-7vw;
			margin-right:-7vw - 2.3vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 4 / 3 / 6;
			margin-left:-7vw - 2.3vw;
		}
	}
	&.wdi_triple-1 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 2;
			margin-left:-7vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 2 / 3 / 4;
		}
		.wdi_item-2 {
			grid-area: 1 / 4 / 3 / 6;
		}
	}

	&.wdi_triple-2 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 3;
		}
		.wdi_item-1 {
			grid-area: 1 / 3 / 3 / 5;
		}
		.wdi_item-2 {
			grid-area: 1 / 5 / 3 / 6;
			margin-right:-7vw;
		}
	}

	&.wdi_triple-3 {
		.work-dynamic-image--grid-container {
			grid-template-rows: repeat(2, auto);
		}
		.wdi_item-0 {
			grid-area: 1 / 1 / 3 / 2;
			margin-right:-12vw;
		}
		.wdi_item-1 {
			grid-area: 1 / 3 / 3 / 4;
			margin-left: -6vw;
			margin-right: -6vw;
		}
		.wdi_item-2 {
			grid-area: 1 / 5 / 3 / 6;
			margin-left:-12vw;
		}
	}


		&.wdi_double-2 .work-dynamic-image--image {
			img {
				height: 100%;
				object-fit: cover;
			}

			&:last-child img {
				object-position: left;
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}
}
