body {

	&[data-entrancebg^="gradient_"] {
		header.header:not(:hover) {
			.header--menu-wrap .primary-navigation ul a {
				color:black;
			}
			.header--menu-title {
				color:black;
			}
			.header--menu-dot {
				background-color: black;
			}
			.brand svg .crew,
			.brand svg .accent {
				fill:black;
			}
		}
		.txt-orange,
		.txt-purple,
		.txt-pink,
		.txt-yellow {
			color:black!important;
		}
		.orange.active,
		.purple.active,
		.pink.active,
		.yellow.active {
			a {
				color:black!important;
			}
			&:before {
				background-color: black!important;
			}
		}
	}

	&[data-entrancebg="gradient_1"] {
		.gradient_bg-overlay.gradient-1 {
			opacity:1;
		}
	}
	&[data-entrancebg="gradient_2"] {
		.gradient_bg-overlay.gradient-2 {
			opacity:1;
		}
	}
	&[data-entrancebg="gradient_3"] {
		.gradient_bg-overlay.gradient-3 {
			opacity:1;
		}
	}
	&[data-entrancebg="gradient_4"] {
		.gradient_bg-overlay.gradient-4 {
			opacity:1;
		}
	}
	&[data-entrancebg="gradient_5"] {
		.gradient_bg-overlay.gradient-5 {
			opacity:1;
		}
	}
	.gradient_bg-overlay {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		opacity:0;
		transition:opacity 0.5s ease;
		&.gradient-1 {
			background:$gradient-1;
		}
		&.gradient-2 {
			background:$gradient-2;
		}
		&.gradient-3 {
			background:$gradient-3;
		}
		&.gradient-4 {
			background:$gradient-4;
		}
		&.gradient-5 {
			background:$gradient-5;
		}
	}
}