.block.work-project-block {

	img {
		height: auto;
	}
	.work-project-block--image_1 {
		direction:rtl;
		img {
			max-width:100%;
			height: auto;
		}
	}
	.work-project-block--image_2 {
		img {
			max-width:100%;
			height: auto;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.work-project-block--image_2 {
			padding-right:12%;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}